import React, { useState } from 'react'
import { Okved2 } from '../../../../Org/Handbook/OKVED2/types'
import FormatText from '../../../../Org/Handbook/OKVED2/components/FormatText'

const PurchaseItemsPopupOkved2Search: React.FC<{
    item: any
    onSelected: (value: Okved2) => void
}> = ({ item, onSelected }) => {
    return (
        <div>
            <div className="flex flex-row w-full justify-start text-center">
                {/* Отступ для вложенности */}
                <div
                    className={`flex flex-row w-full gap-1 mx-1 bg-[#2A282B] text-white hover:bg-[#373538] text-[11px] rounded cursor-pointer`}
                    onDoubleClick={() => onSelected(item)}
                >
                    <div className="flex flex-col w-full items-center justify-center">
                        <div className="flex flex-row w-full items-center justify-center">
                            <div className="flex w-8 items-center justify-center text-center text-[#8A8A8A]"></div>
                            <div className="flex w-16 text-left justify-left items-center pl-1">
                                {item.code}
                            </div>
                            <div className="flex flex-row flex-1 items-center justify-left text-left px-2 py-2 gap-2">
                                <div className="flex items-center justify-left text-left">
                                    {item.name}
                                </div>
                            </div>
                        </div>
                        {item.add_info ? (
                            <div className="flex flex-row w-full items-center justify-start ">
                                <div className="flex w-8 text-left justify-left items-center pl-1 "></div>
                                <div className="flex w-16 text-left justify-left items-center pl-1 "></div>
                                <div className="flex flex-1 items-center justify-left text-left text-[11px] text-[#8A8A8A] pb-2 whitespace-pre-wrap">
                                    <FormatText text={item.add_info} />
                                </div>
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PurchaseItemsPopupOkved2Search
