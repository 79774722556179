import React, { useState, useEffect, useMemo } from 'react'
import { Okato } from '../types'
import { Okpd2 } from '../../../../Org/Handbook/OKPD2/types'
import { Okved2 } from '../../../../Org/Handbook/OKVED2/types'
import useDebounce from '../../../../../../hooks/useDebounce'
import useFetch from '../../../../../../hooks/useFetch'
import LoadingIcon from '../../../../../../components/LoadingIcon'
import PurchaseItemsPopupOkpd2 from './PurchaseItemsPopupOkpd2'
import PurchaseItemsPopupOkpd2Search from './PurchaseItemsPopupOkpd2Search'
import PurchaseItemsPopupOkved2 from './PurchaseItemsPopupOkved2'
import PurchaseItemsPopupOkved2Search from './PurchaseItemsPopupOkved2Search'
import PurchaseItemsPopupOkato from './PurchaseItemsPopupOkato'
import PurchaseItemsPopupOkatoSearch from './PurchaseItemsPopupOkatoSearch'

type LayoutProps = {
    type: string
    posMSPStatus: boolean | null
    purchaseMethodCode: number | null
    purchaseMethodName: string | null
    isFirstSelected: boolean
    onSelected: (value: Okpd2 | Okved2 | Okato) => void
    onClose: () => void
}

const debounce = <T extends (...args: any[]) => void>(
    func: T,
    delay: number
): T => {
    let timeoutId: ReturnType<typeof setTimeout>
    return ((...args: Parameters<T>) => {
        clearTimeout(timeoutId)
        timeoutId = setTimeout(() => {
            func(...args)
        }, delay)
    }) as T
}

const PurchaseItemsPopup: React.FC<LayoutProps> = (props) => {
    const [query, setQuery] = useState('')

    const debouncedQuery = useDebounce(query, 500)

    const [purchaseSingle, setPurchaseSingle] = useState(false)
    const searchString = 'единствен'

    const apiUrl =
        props.type == 'okpd2'
            ? debouncedQuery
                ? process.env.REACT_APP_API_URL +
                  (/^[0-9]/.test(debouncedQuery[0])
                      ? `/api/backend/v1/okpd2/search_code?text_query=${encodeURIComponent(debouncedQuery)}`
                      : `/api/backend/v1/okpd2/search_name?text_query=${encodeURIComponent(debouncedQuery)}`)
                : process.env.REACT_APP_API_URL + `/api/backend/v1/okpd2/code`
            : props.type == 'okved2'
              ? debouncedQuery
                  ? process.env.REACT_APP_API_URL +
                    (/^[0-9]/.test(debouncedQuery[0])
                        ? `/api/backend/v1/okved2/search_code?text_query=${encodeURIComponent(debouncedQuery)}`
                        : `/api/backend/v1/okved2/search_name?text_query=${encodeURIComponent(debouncedQuery)}`)
                  : process.env.REACT_APP_API_URL +
                    `/api/backend/v1/okved2/code`
              : props.type == 'okato'
                ? debouncedQuery
                    ? process.env.REACT_APP_API_URL +
                      `/api/backend/v1/okato/fast_search?text_query=${encodeURIComponent(debouncedQuery)}`
                    : process.env.REACT_APP_API_URL + `/api/backend/v1/okato`
                : ''

    // Опции для fetch, мемоизированные с помощью useMemo
    const fetchOptions: RequestInit = useMemo(
        () => ({
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        }),
        []
    ) // зависимости пустые, объект будет создан один раз

    const { data, loading, error } = useFetch<
        Okpd2[] | Okved2[] | Okato[] | null
    >(apiUrl, fetchOptions)

    useEffect(() => {
        setPurchaseSingle(
            !!props.purchaseMethodName
                ?.toLowerCase()
                .includes(searchString.toLowerCase())
        )
    }, [props.purchaseMethodName])

    const handlerSearchClear = () => {
        setQuery('')
    }

    return (
        <div
            className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center"
            onClick={() => props.onClose()}
        >
            <div
                className="flex flex-col items-start justify-start p-5 rounded-lg bg-[#262427] w-[1300px] gap-2"
                onClick={(e) => e.stopPropagation()}
            >
                <h2 className="text-white">
                    {(() => {
                        switch (props.type) {
                            case 'okpd2':
                                return 'Общероссийский классификатор продукции по видам экономической деятельности (ОКПД2)'
                            case 'okved2':
                                return 'Общероссийский классификатор видов экономической деятельности (ОКВЭД2)'
                            case 'okato':
                                return 'Общероссийский классификатор объектов административно-территориального деления (ОКАТО)'
                        }
                    })()}
                </h2>
                {props.type == 'okpd2' ? (
                    <>
                        <div className="flex flex-row w-full items-center justify-start gap-2 text-[12.8px] text-white">
                            <div className="flex flex-row w-5 h-5 items-center justify-center text-white">
                                <svg
                                    id="info-circle"
                                    className="w-5 h-5"
                                    data-name="Layer 1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                >
                                    <path
                                        fill="#FFBF00"
                                        d="M12,2A10,10,0,1,0,22,12,10.01114,10.01114,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8.00917,8.00917,0,0,1,12,20Zm0-8.5a1,1,0,0,0-1,1v3a1,1,0,0,0,2,0v-3A1,1,0,0,0,12,11.5Zm0-4a1.25,1.25,0,1,0,1.25,1.25A1.25,1.25,0,0,0,12,7.5Z"
                                    ></path>
                                </svg>
                            </div>
                            <div className="flex flex-row w-full">
                                {props.type == 'okpd2'
                                    ? props.purchaseMethodCode
                                        ? purchaseSingle
                                            ? props.isFirstSelected
                                                ? props.posMSPStatus
                                                    ? 'В данный момент вы можете работать только с кодами ОКПД2, входящими в перечень закупок у субъектов МСП'
                                                    : 'Вам доступны исключительно коды ОКПД2, не входящие в перечень закупок у субъектов МСП'
                                                : 'При выборе кода ОКПД2, относящегося к закупками у субъектов МСП, доступ к остальным кодам будет ограничен. В дальнейшем вы сможете выбирать только коды из перечня МСП. Учитывайте это при выборе.'
                                            : props.posMSPStatus
                                              ? 'В данный момент вы можете работать только с кодами ОКПД2, входящими в перечень закупок у субъектов МСП'
                                              : 'Вам доступны исключительно коды ОКПД2, не входящие в перечень закупок у субъектов МСП'
                                        : props.posMSPStatus
                                          ? 'В данный момент вы можете работать только с кодами ОКПД2, входящими в перечень закупок у субъектов МСП'
                                          : props.isFirstSelected
                                            ? props.posMSPStatus
                                                ? 'В данный момент вы можете работать только с кодами ОКПД2, входящими в перечень закупок у субъектов МСП'
                                                : 'Вам доступны исключительно коды ОКПД2, не входящие в перечень закупок у субъектов МСП'
                                            : 'При выборе кода ОКПД2, относящегося к закупками у субъектов МСП, доступ к остальным кодам будет ограничен. В дальнейшем вы сможете выбирать только коды из перечня МСП. Учитывайте это при выборе.'
                                    : 'При выборе кода ОКПД2, относящегося к закупками у субъектов МСП, доступ к остальным кодам будет ограничен. В дальнейшем вы сможете выбирать только коды из перечня МСП. Учитывайте это при выборе.'}
                            </div>
                        </div>
                    </>
                ) : (
                    ''
                )}
                <div className="flex flex-col w-full mt-2 space-y-1">
                    <div className="flex flex-row gap-1 h-10 text-[#8A8A8A] text-[10px] p-1 items-center justify-center">
                        <div className="flex w-6 items-center justify-center text-center">
                            {loading && (
                                <div className="flex w-6 items-center justify-center text-center text-[#8A8A8A]">
                                    <svg
                                        className="animate-spin h-4 w-4 text-white"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                    >
                                        <circle
                                            className="opacity-25"
                                            cx="12"
                                            cy="12"
                                            r="10"
                                            stroke="currentColor"
                                            strokeWidth="4"
                                        ></circle>
                                        <path
                                            className="opacity-75"
                                            fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                        ></path>
                                    </svg>
                                </div>
                            )}
                        </div>
                        <input
                            className="form-input flex flex-row flex-1 focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                            value={query}
                            onChange={(e) => setQuery(e.target.value)}
                            placeholder="Введите код или нименование"
                        />
                        <div className="flex w-6 items-center justify-center text-center">
                            <button
                                className="w-6 h-6 grid rounded-full place-items-center hover:bg-[#656365]"
                                onClick={handlerSearchClear}
                                title="Очистить фильтр"
                            >
                                <svg
                                    className="w-3 h-3"
                                    xmlns="http://www.w3.org/2000/svg"
                                    data-name="Layer 1"
                                    viewBox="0 0 24 24"
                                    id="x"
                                >
                                    <path
                                        fill="#FFFFFF"
                                        d="M8 19a3 3 0 0 1-3-3V8a3 3 0 0 1 3-3 1 1 0 0 0 0-2 5 5 0 0 0-5 5v8a5 5 0 0 0 5 5 1 1 0 0 0 0-2Zm7.71-3.29a1 1 0 0 0 0-1.42L13.41 12l2.3-2.29a1 1 0 0 0-1.42-1.42L12 10.59l-2.29-2.3a1 1 0 0 0-1.42 1.42l2.3 2.29-2.3 2.29a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0l2.29-2.3 2.29 2.3a1 1 0 0 0 1.42 0ZM16 3a1 1 0 0 0 0 2 3 3 0 0 1 3 3v8a3 3 0 0 1-3 3 1 1 0 0 0 0 2 5 5 0 0 0 5-5V8a5 5 0 0 0-5-5Z"
                                    ></path>
                                </svg>
                            </button>
                        </div>
                    </div>

                    <div className="flex flex-1 justify-center max-h-[700px]">
                        <div className="flex flex-col h-full w-full">
                            {/* // body */}
                            <div className="flex h-full">
                                {/* table */}
                                <div className="flex flex-col h-full w-full">
                                    {/* name */}
                                    <div className="flex flex-row gap-1 h-12 bg-[#2A282B] text-[#8A8A8A] text-[10px] p-1 rounded-t-lg">
                                        <div className="flex flex-col w-8 justify-center items-center text-center"></div>
                                        <div className="flex flex-col w-16 justify-center items-center text-center">
                                            Код
                                        </div>
                                        <div className="flex flex-1 items-center justify-center text-center">
                                            Наименование и описание
                                        </div>
                                        {props.type != 'okato' ? (
                                            <div className="flex flex-row w-fit items-center justify-end text-left px-2 py-2 gap-1 ">
                                                <div className="flex w-24 text-center justify-center items-center pl-1 ">
                                                    Закупка <br /> МСП
                                                </div>
                                                <div className="flex w-24 text-center justify-center items-center pl-1 ">
                                                    Нацрежим <br /> (запрет)
                                                </div>
                                                <div className="flex w-24 text-center justify-center items-center pl-1 ">
                                                    Нацрежим <br />{' '}
                                                    (ограничение)
                                                </div>
                                                <div className="flex w-24 text-center justify-center items-center pl-1 ">
                                                    Минимальная доля товаров из
                                                    РФ
                                                </div>
                                                <div className="flex w-24 text-center justify-center items-center pl-1 ">
                                                    В электронной форме
                                                </div>
                                            </div>
                                        ) : (
                                            ''
                                        )}

                                        <div className="flex flex-col w-2 justify-center items-center text-center"></div>
                                    </div>

                                    {/* data */}
                                    <div className="flex flex-col overflow-y-auto h-[600px] gap-1 py-1 bg-[#1D1C1E] rounded-b-lg">
                                        {/* Вывод списка ОКВЭД2 */}
                                        {error && (
                                            <p>Ошибка: {error.message}</p>
                                        )}
                                        {data &&
                                            (data.length > 0 ? (
                                                data.map((item) =>
                                                    props.type == 'okpd2' ? (
                                                        query == '' ? (
                                                            <PurchaseItemsPopupOkpd2
                                                                key={item.guid}
                                                                item={item}
                                                                level={0}
                                                                onSelected={
                                                                    props.onSelected
                                                                }
                                                                posMSPStatus={
                                                                    props.posMSPStatus
                                                                }
                                                            />
                                                        ) : (
                                                            <PurchaseItemsPopupOkpd2Search
                                                                key={item.guid}
                                                                item={item}
                                                                onSelected={
                                                                    props.onSelected
                                                                }
                                                                posMSPStatus={
                                                                    props.posMSPStatus
                                                                }
                                                            />
                                                        )
                                                    ) : props.type ==
                                                      'okved2' ? (
                                                        query == '' ? (
                                                            <PurchaseItemsPopupOkved2
                                                                key={item.guid}
                                                                item={item}
                                                                level={0}
                                                                onSelected={
                                                                    props.onSelected
                                                                }
                                                            />
                                                        ) : (
                                                            <PurchaseItemsPopupOkved2Search
                                                                key={item.guid}
                                                                item={item}
                                                                onSelected={
                                                                    props.onSelected
                                                                }
                                                            />
                                                        )
                                                    ) : props.type ==
                                                      'okato' ? (
                                                        query == '' ? (
                                                            <PurchaseItemsPopupOkato
                                                                key={item.guid}
                                                                item={item}
                                                                onSelected={
                                                                    props.onSelected
                                                                }
                                                            />
                                                        ) : (
                                                            <PurchaseItemsPopupOkatoSearch
                                                                key={item.guid}
                                                                item={item}
                                                                onSelected={
                                                                    props.onSelected
                                                                }
                                                            />
                                                        )
                                                    ) : (
                                                        ''
                                                    )
                                                )
                                            ) : (
                                                <div className="flex w-full h-full items-center justify-center text-[#8A8A8A]">
                                                    Ничего не найдено
                                                </div>
                                            ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div style={{ height: '600px' }}>
                        {data !== null && data.length > 0 && (
                            <ul className="h-full overflow-y-auto">
                                {data.map((item, index) => (
                                    <li
                                        key={index}
                                        className="block p-2 hover:bg-zinc-700 cursor-pointer text-[12.8px] text-white border-b border-[#333033]"
                                        onClick={() => props.onSelected(item)}
                                    >
                                        {item.code}
                                        {' - '}
                                        {item.name}
                                        {props.type == 'okpd2'
                                            ? item.is_msp
                                                ? ' - МСП'
                                                : ''
                                            : ''}
                                    </li>
                                ))}
                            </ul>
                        )}
                        {data !== null && data.length === 0 && (
                            <li className="flex p-1 rounded-md text-sm text-white items-center justify-center">
                                Ничего не найдено
                            </li>
                        )}
                    </div> */}
                </div>
                <div className="flex flex-row w-full mt-5 text-right justify-end">
                    <button
                        className="px-4 py-1 rounded-full shadow-md text-xs text-white bg-[#494849] hover:bg-[#656365]"
                        onClick={() => props.onClose()}
                    >
                        Отмена
                    </button>
                </div>
            </div>
        </div>
    )
}

export default PurchaseItemsPopup
