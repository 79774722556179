import React, { useState, useEffect } from 'react'
import Section from '../../../../../../components/Section'
import Checkbox from '../../../../../../components/form/Checkbox'
import { PurchaseMethodType, IgnoredCategory } from '../types'
import { ListEp } from '../../../../Org/Lists/types'

interface Props {
    posStatus: string
    methodCode: number | null
    onMethodCodeChange: (value: number) => void
    method: string | null
    onMethodChange: (value: string) => void
    isElectronic: boolean | null
    onIsElectronicChange: (value: boolean | null) => void
    basisSingleSupplier: string | null
    onBasisSingleSupplierChange: (value: string) => void
    areParticipantsSME: boolean | null
    onAreParticipantsSMEChange: (value: boolean) => void
    isPlannedThirdYear: boolean
    onIsPlannedThirdYearChange: (value: boolean) => void
    isInnovativeProduct: boolean
    onIsInnovativeProductChange: (value: boolean) => void
    isExcludedForSME: boolean
    onIsExcludedForSMEChange: (value: boolean) => void
    category: number | null
    onCategoryChange: (value: number | null) => void
    posMSPStatus: boolean
}

const getPurchaseMethodList = async (): Promise<PurchaseMethodType[]> => {
    return fetch(
        process.env.REACT_APP_API_URL +
            '/api/backend/v1/purchase_method_active',
        {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        }
    )
        .then((response) => {
            if (response.ok) {
                return response.json()
            } else {
                throw new Error('Ошибка запроса на способы закупок')
            }
        })
        .catch((e) => {
            console.error(e)
        })
}

const getSingleSupplierPurchase = async (): Promise<ListEp[]> => {
    return fetch(
        process.env.REACT_APP_API_URL +
            '/api/backend/v1/list_single_supplier_purchase',
        {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        }
    )
        .then((response) => {
            if (response.ok) {
                return response.json()
            } else {
                throw new Error('Ошибка запроса на способы закупок')
            }
        })
        .catch((e) => {
            console.error(e)
        })
}

const getIgnoredCategory = async (): Promise<IgnoredCategory[]> => {
    return fetch(
        process.env.REACT_APP_API_URL + '/api/backend/v1/ignored_category',
        {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        }
    )
        .then((response) => {
            if (response.ok) {
                return response.json()
            } else {
                throw new Error('Ошибка запроса на способы закупок')
            }
        })
        .catch((e) => {
            console.error(e)
        })
}

const PurchaseMethod: React.FC<Props> = (props) => {
    // const { basisSingleSupplier, onBasisSingleSupplierChange } = props
    // const { category, onCategoryChange } = props

    const [disabledOnlyMspCheck, setDisabledOnlyMspCheck] = useState(true)
    const [purchaseMethodList, setPurchaseMethodList] = useState<
        PurchaseMethodType[]
    >([])
    const [singleSupplierPurchaseList, setSingleSupplierPurchaseList] =
        useState<ListEp[]>([])
    const [ignoredCategoryList, setIgnoredCategoryList] = useState<
        IgnoredCategory[]
    >([])

    useEffect(() => {
        const fetchPurchaseMethods = async () => {
            const methods = await getPurchaseMethodList()
            if (methods) {
                setPurchaseMethodList(methods) // Обновляем состояние
            }

            const singleMethods = await getSingleSupplierPurchase()
            if (singleMethods) {
                setSingleSupplierPurchaseList(singleMethods) // Обновляем состояние
            }

            const ignoredCategories = await getIgnoredCategory()
            if (ignoredCategories) {
                setIgnoredCategoryList(ignoredCategories) // Обновляем состояние
            }
        }
        fetchPurchaseMethods()
    }, [])

    useEffect(() => {
        const matchingMethod = purchaseMethodList.find(
            (method) =>
                method.purchase_method_name.toLowerCase() ===
                props.method?.toLowerCase()
        )

        props.onMethodCodeChange(matchingMethod?.purchase_method_code || 0)
        props.onIsElectronicChange(matchingMethod?.is_electronic || false)

        if (props.method != null) {
            if (props.method?.toLowerCase().includes('единствен')) {
                setDisabledOnlyMspCheck(false)
                props.onAreParticipantsSMEChange(
                    props.areParticipantsSME || false
                )
            } else if (matchingMethod && matchingMethod.purchase_smb_type) {
                setDisabledOnlyMspCheck(true)
                props.onAreParticipantsSMEChange(true)
            } else {
                setDisabledOnlyMspCheck(true)
                props.onAreParticipantsSMEChange(false)
            }
        } else {
            setDisabledOnlyMspCheck(props.posMSPStatus)
            props.onAreParticipantsSMEChange(props.posMSPStatus)
        }
    }, [props.method, purchaseMethodList])

    useEffect(() => {
        if (props.isExcludedForSME != true) props.onCategoryChange(null)
    }, [props.isExcludedForSME])

    return (
        <Section title="Информация о закупке">
            <div className="form-label">Способ закупки</div>
            <select
                className="w-full form-select"
                value={props?.method || ''}
                onChange={(e) => props.onMethodChange(e.target.value)}
            >
                <option disabled value="" className="text-[#8A8A8A]">
                    Выбрать способ закупки
                </option>
                {purchaseMethodList?.length > 0 ? (
                    purchaseMethodList.map((method, index) => (
                        <option key={index} value={method.purchase_method_name}>
                            {method.purchase_method_name}
                        </option>
                    ))
                ) : (
                    <option disabled>Данные отсутствуют</option>
                )}
            </select>

            {props.method?.toLowerCase().includes('единствен') && (
                <>
                    <div className="mt-2 form-label">
                        Основание для проведения закупки у единственного
                        поставщика (исполнителя, подрядчика)
                    </div>
                    <select
                        className="w-full form-select text-sm text-gray-700 whitespace-nowrap overflow-hidden text-ellipsis"
                        value={props.basisSingleSupplier || ''}
                        onChange={(e) =>
                            props.onBasisSingleSupplierChange(e.target.value)
                        }
                    >
                        <option disabled value="" className="text-[#8A8A8A]">
                            Выбрать способ закупки
                        </option>
                        {singleSupplierPurchaseList?.length > 0 ? (
                            singleSupplierPurchaseList.map((smethod, index) => (
                                <option
                                    key={index}
                                    value={
                                        smethod.single_supplier_purchase_name
                                    }
                                >
                                    {smethod.single_supplier_purchase_paragraph +
                                        '. ' +
                                        smethod.single_supplier_purchase_name}
                                </option>
                            ))
                        ) : (
                            <option disabled>Данные отсутствуют</option>
                        )}
                    </select>
                </>
            )}

            <div className="mt-5 flex flex-col space-y-3">
                <Checkbox
                    label="Закупка, участниками которой являются только субъекты малого и среднего предпринимательства"
                    checked={props?.areParticipantsSME || false}
                    onChange={props.onAreParticipantsSMEChange}
                    disabled={disabledOnlyMspCheck}
                />

                {props?.areParticipantsSME == true ? (
                    <Checkbox
                        label="Закупка запланирована на третий или последующие годы реализации плана закупки"
                        checked={props.isPlannedThirdYear}
                        onChange={props.onIsPlannedThirdYearChange}
                        disabled={false}
                    />
                ) : (
                    ''
                )}

                <Checkbox
                    label="Закупка товаров, работ, услуг, удовлетворяющих критериям отнесения к инновационной продукции, высокотехнологичной продукции"
                    checked={props.isInnovativeProduct}
                    onChange={props.onIsInnovativeProductChange}
                    disabled={false}
                />
                <Checkbox
                    label="Закупка исключается при расчете годового объема закупок, участниками которых являются субъекты малого и среднего предпринимательства"
                    checked={props.isExcludedForSME}
                    onChange={props.onIsExcludedForSMEChange}
                    disabled={false}
                />
            </div>

            {props.isExcludedForSME && (
                <>
                    <div className="mt-2 form-label">
                        Категория закупки в соответствии с пунктом 7
                        постановления Правительства РФ от 11.12.2014 № 1352
                    </div>
                    <select
                        className="flex w-full form-select max-h-52"
                        value={props.category || 0}
                        onChange={(e) =>
                            props.onCategoryChange(Number(e.target.value))
                        }
                    >
                        <option disabled value={0} className="text-[#8A8A8A]">
                            Выбрать способ закупки
                        </option>
                        {ignoredCategoryList?.length > 0 ? (
                            ignoredCategoryList.map((category, index) => (
                                <option key={index} value={category.code}>
                                    {category.code + '. ' + category.name}
                                </option>
                            ))
                        ) : (
                            <option disabled>Данные отсутствуют</option>
                        )}
                    </select>
                </>
            )}
        </Section>
    )
}

export default PurchaseMethod
