import React, { useState } from 'react'
import { Okato } from '../types'

const PurchaseItemsPopupOkved2Search: React.FC<{
    item: any
    onSelected: (value: Okato) => void
}> = ({ item, onSelected }) => {
    return (
        <div>
            <div className="flex flex-row w-full justify-start text-center">
                {/* Отступ для вложенности */}
                <div
                    className={`flex flex-row w-full gap-1 mx-1 bg-[#2A282B] text-white hover:bg-[#373538] text-[11px] rounded cursor-pointer`}
                    onDoubleClick={() => onSelected(item)}
                >
                    <div className="flex flex-row w-full items-center justify-center gap-2">
                        <div className="flex flex-col w-8 justify-center items-center text-center"></div>
                        <div className="flex w-16 text-left justify-left items-center pl-1 ">
                            {item.code}
                        </div>
                        <div className="flex flex-1 items-center justify-left text-left px-2 py-2  ">
                            {item.name}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PurchaseItemsPopupOkved2Search
