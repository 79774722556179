import React, { useState, useEffect, useRef } from 'react'
import { PlanPositionRow, Okei } from '../types'
import { Okpd2 } from '../../../../Org/Handbook/OKPD2/types'

type LayoutProps = {
    index: number
    positionRow: PlanPositionRow
    showRegion: boolean
    onNameChanged: (value: string) => void
    onImpossibleToDetermineAttrChanged: (
        attr: boolean | null,
        value_qty: number | null,
        code_okei: string | null,
        value_okei: string | null
    ) => void
    onCountChanged: (value: number | null) => void
    onOkeiChanged: (code: string | null, value: string | null) => void
    onTypePurchaseChanged: (value: string) => void
    onDropdownSelected: (type: string) => void
    onDeleteClick: () => void
    okeiList: Okei[]
}

const PurchaseItemsTableRow: React.FC<LayoutProps> = ({
    index,
    positionRow,
    showRegion,
    onNameChanged,
    onImpossibleToDetermineAttrChanged,
    onCountChanged,
    onOkeiChanged,
    onTypePurchaseChanged,
    onDropdownSelected,
    onDeleteClick,
    okeiList,
}) => {
    const textareaRef = useRef<HTMLTextAreaElement>(null)
    const [text, setText] = useState('')
    const [dataSearchOkpd2, setDataSearchOkpd2] = useState<Okpd2 | null>(null)

    // Функция для запроса данных OKPD2
    const searchOkpd2 = async (codeOkpd2: string) => {
        if (!codeOkpd2) {
            setDataSearchOkpd2(null)
            return
        }

        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/api/backend/v1/okpd2/search_code?text_query=${codeOkpd2}`,
                {
                    method: 'GET',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            )

            if (response.ok) {
                const data: Okpd2[] = await response.json()
                if (data.length > 0) {
                    setDataSearchOkpd2(data[0]) // Берем первый результат
                } else {
                    setDataSearchOkpd2(null)
                }
            } else {
                throw new Error('Ошибка запроса на поиск OKPD2')
            }
        } catch (error) {
            console.error(error)
            setDataSearchOkpd2(null)
        }
    }

    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto' // Сбрасываем высоту перед пересчетом
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`
        }
    }, [text]) // Вызываем при изменении текста

    // Вызываем searchOkpd2 при монтировании компонента и при изменении positionRow.okpd2_code
    useEffect(() => {
        searchOkpd2(positionRow.okpd2_code ?? '')
    }, [positionRow.okpd2_code])

    return (
        <div className="flex flex-row gap-1 m-1 bg-[#242225] text-[11px] rounded items-start">
            <div className="flex flex-row h-8 w-8 items-center justify-center text-center">
                <div className="flex w-6 h-6 rounded-full items-center justify-center bg-[#242225] hover:bg-[#656365]">
                    <input type="checkbox" className="p-2" />
                </div>
            </div>

            <div className="flex flex-row h-8 w-12 items-center justify-center text-center text-white">
                {index + 1}
            </div>

            <div className="flex flex-1 min-h-8 text-left min-w-72">
                <textarea
                    ref={textareaRef}
                    className="flex h-8 min-h-8 w-full p-1 px-2 rounded-md border-0 bg-[#181818] text-white focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                    maxLength={2000}
                    value={positionRow.additional_info ?? ''}
                    onChange={(e) => {
                        onNameChanged(e.target.value)
                        setText(e.target.value)
                    }}
                    onInput={(e) => {
                        const target = e.target as HTMLTextAreaElement
                        target.style.height = 'auto' // Сброс высоты перед обновлением
                        target.style.height = `${target.scrollHeight}px` // Устанавливаем новую высоту
                    }}
                    rows={1}
                />
            </div>

            <div className="flex flex-row h-8 w-8 items-center justify-center text-center">
                <div
                    className="flex w-6 h-6 rounded-full items-center justify-center bg-[#242225] hover:bg-[#656365]"
                    title="Невозможно определить количество"
                >
                    <input
                        type="checkbox"
                        className="p-2"
                        checked={
                            positionRow.impossible_to_determine_attr || false
                        }
                        onChange={(e) => {
                            onImpossibleToDetermineAttrChanged(
                                e.target.checked,
                                null,
                                null,
                                null
                            )

                            console.log('Значение chacked ' + e.target.checked)
                        }}
                    />
                </div>
            </div>

            <div className="flex flex-row h-8 w-28 items-center justify-center text-center">
                <input
                    type="number"
                    min="0"
                    lang="ru"
                    name="purchase_item_count"
                    className={`h-8 rounded-md text-primary text-center w-full pr-1  focus:outline-none focus:ring-1 focus:ring-[#FFFFFF] bg-[#181818] border-none text-white ${positionRow.impossible_to_determine_attr ? 'hidden' : ''}`}
                    style={{ colorScheme: 'dark' }}
                    value={positionRow.qty ?? ''}
                    onChange={(e) => onCountChanged(+e.target.value)}
                    disabled={positionRow.impossible_to_determine_attr || false}
                />
            </div>

            <div className="flex flex-row h-8 w-32 items-center justify-center text-center">
                <select
                    name="purchase_item_count_type"
                    className={`h-8 px-2 rounded-md w-full focus:outline-none focus:ring-1 focus:ring-[#FFFFFF] bg-[#181818] border-none text-white ${positionRow.impossible_to_determine_attr ? 'hidden' : ''}`}
                    title={positionRow.okei_name ?? ''}
                    onChange={(e) => {
                        const selectedCode = e.target.value
                        const selectedOkei = okeiList.find(
                            (okei) => okei.code === selectedCode
                        )
                        onOkeiChanged(selectedCode, selectedOkei?.name || null)
                    }}
                    value={positionRow.okei_code ?? ''}
                    disabled={positionRow.impossible_to_determine_attr || false}
                >
                    <option className="text-[#8A8A8A]" value="">
                        Выбрать
                    </option>
                    {okeiList.map((okei, index_okei) => (
                        <option
                            key={index_okei}
                            value={okei.code}
                            title={`${okei.name} (${okei.symbol?.toLowerCase()})`}
                        >
                            {okei.name} ({okei.symbol?.toLowerCase()})
                        </option>
                    ))}
                </select>
            </div>

            <div className="flex flex-row h-8 w-28 items-center justify-center text-center text-white">
                <select
                    name="purchase_item_type_purchase"
                    className="h-8 px-2 rounded-md bg-[#181818] w-full focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                    value={positionRow.type_object_purchase ?? ''}
                    onChange={(e) => onTypePurchaseChanged(e.target.value)}
                >
                    <option className="text-[#8A8A8A]" value="">
                        Выбрать
                    </option>
                    <option value="G">Товар</option>
                    <option value="W">Работа</option>
                    <option value="S">Услуга</option>
                </select>
            </div>

            <div className="relative flex flex-row w-28 items-center justify-end">
                <input
                    type="text"
                    className="flex pl-2 pr-9 h-8 w-full rounded-md bg-[#181818] text-white focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                    value={positionRow.okpd2_code ?? ''}
                    readOnly
                    title={`${positionRow.okpd2_code} - ${positionRow.okpd2_name}`}
                />

                <button
                    className="absolute flex w-6 h-6 mr-1 rounded-full items-center justify-center bg-[#181818] hover:bg-[#656365]"
                    onClick={() => onDropdownSelected('okpd2')}
                >
                    <svg
                        className="w-3 h-3"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        id="bars"
                    >
                        <path
                            fill="#FFFFFF"
                            d="M3,8H21a1,1,0,0,0,0-2H3A1,1,0,0,0,3,8Zm18,8H3a1,1,0,0,0,0,2H21a1,1,0,0,0,0-2Zm0-5H3a1,1,0,0,0,0,2H21a1,1,0,0,0,0-2Z"
                        ></path>
                    </svg>
                </button>
            </div>

            <div className="relative flex flex-row w-28 items-center justify-end">
                <input
                    type="text"
                    className="flex pl-2 pr-9 h-8 w-full rounded-md bg-[#181818] text-white focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                    value={positionRow.okved2_code ?? ''}
                    readOnly
                    title={`${positionRow.okved2_code} - ${positionRow.okved2_name}`}
                />

                <button
                    className="absolute flex w-6 h-6 mr-1 rounded-full items-center justify-center bg-[#181818] hover:bg-[#656365]"
                    onClick={() => onDropdownSelected('okved2')}
                >
                    <svg
                        className="w-3 h-3"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        id="bars"
                    >
                        <path
                            fill="#FFFFFF"
                            d="M3,8H21a1,1,0,0,0,0-2H3A1,1,0,0,0,3,8Zm18,8H3a1,1,0,0,0,0,2H21a1,1,0,0,0,0-2Zm0-5H3a1,1,0,0,0,0,2H21a1,1,0,0,0,0-2Z"
                        ></path>
                    </svg>
                </button>
            </div>

            {showRegion && (
                <div className="relative flex flex-row w-44 items-center justify-end">
                    <input
                        type="text"
                        className="flex pl-2 pr-9 h-8 w-full rounded-md bg-[#181818] text-white focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                        value={positionRow.region ?? ''}
                        readOnly
                        title={`${positionRow.okato} - ${positionRow.region}`}
                    />

                    <button
                        className="absolute flex w-6 h-6 mr-1 rounded-full items-center justify-center bg-[#181818] hover:bg-[#656365]"
                        onClick={() => onDropdownSelected('okato')}
                    >
                        <svg
                            className="w-3 h-3"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            id="bars"
                        >
                            <path
                                fill="#FFFFFF"
                                d="M3,8H21a1,1,0,0,0,0-2H3A1,1,0,0,0,3,8Zm18,8H3a1,1,0,0,0,0,2H21a1,1,0,0,0,0-2Zm0-5H3a1,1,0,0,0,0,2H21a1,1,0,0,0,0-2Z"
                            ></path>
                        </svg>
                    </button>
                </div>
            )}

            <div className="flex flex-row h-8 w-fit items-center justify-center text-center gap-1">
                <div
                    className=" flex w-6 h-5 items-center justify-center border-l border-[#464447] pl-1"
                    title="Закупка только у субъектов МСП"
                >
                    {dataSearchOkpd2?.is_msp ? (
                        <svg
                            className="w-5 h-5"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            id="check"
                        >
                            <path
                                fill="#187A33"
                                d="M18.71,7.21a1,1,0,0,0-1.42,0L9.84,14.67,6.71,11.53A1,1,0,1,0,5.29,13l3.84,3.84a1,1,0,0,0,1.42,0l8.16-8.16A1,1,0,0,0,18.71,7.21Z"
                            ></path>
                        </svg>
                    ) : (
                        ''
                    )}
                </div>
                <div
                    className=" flex w-6 h-5 items-center justify-center"
                    title={`${dataSearchOkpd2?.is_rf_ban && dataSearchOkpd2?.is_rf_limit ? `Запрет + Ограничение ${dataSearchOkpd2?.is_rf_quota ? `+ Доля РФ (${dataSearchOkpd2?.quota_percent}%)` : ``}` : `${dataSearchOkpd2?.is_rf_ban ? `Запрет ${dataSearchOkpd2?.is_rf_quota ? `+ Доля РФ (${dataSearchOkpd2?.quota_percent}%)` : ``}` : `${dataSearchOkpd2?.is_rf_limit ? `Ограничение ${dataSearchOkpd2?.is_rf_quota ? `+ Доля РФ (${dataSearchOkpd2?.quota_percent}%)` : ``}` : `${dataSearchOkpd2?.is_rf_quota ? `Доля РФ (${dataSearchOkpd2?.quota_percent}%)` : ``}`}`}`}`}
                >
                    {dataSearchOkpd2?.is_rf_ban &&
                    dataSearchOkpd2?.is_rf_limit ? (
                        <svg
                            className="w-4 h-4"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            id="check"
                        >
                            <path
                                fill="#EF4444"
                                d="M16.5303 6.46967C16.8232 6.76256 16.8232 7.23744 16.5303 7.53033L6.53033 17.5303C6.38968 17.671 6.19891 17.75 6 17.75 5.80109 17.75 5.61032 17.671 5.46967 17.5303L1.46967 13.5303C1.17678 13.2374 1.17678 12.7626 1.46967 12.4697 1.76256 12.1768 2.23744 12.1768 2.53033 12.4697L6 15.9393 15.4697 6.46967C15.7626 6.17678 16.2374 6.17678 16.5303 6.46967zM22.5303 6.46966C22.8232 6.76254 22.8232 7.23742 22.5303 7.53032L12.5308 17.5303C12.2379 17.8232 11.7631 17.8232 11.4702 17.5304L9.96975 16.0304C9.67681 15.7376 9.67674 15.2627 9.96959 14.9697 10.2624 14.6768 10.7373 14.6767 11.0303 14.9696L12.0004 15.9394 21.4697 6.46968C21.7625 6.17678 22.2374 6.17677 22.5303 6.46966z"
                            ></path>
                        </svg>
                    ) : dataSearchOkpd2?.is_rf_ban ? (
                        <svg
                            className="w-5 h-5"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            id="check"
                        >
                            <path
                                fill="#EF4444"
                                d="M18.71,7.21a1,1,0,0,0-1.42,0L9.84,14.67,6.71,11.53A1,1,0,1,0,5.29,13l3.84,3.84a1,1,0,0,0,1.42,0l8.16-8.16A1,1,0,0,0,18.71,7.21Z"
                            ></path>
                        </svg>
                    ) : dataSearchOkpd2?.is_rf_limit ? (
                        <svg
                            className="w-5 h-5"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            id="check"
                        >
                            <path
                                fill="#FAB502"
                                d="M18.71,7.21a1,1,0,0,0-1.42,0L9.84,14.67,6.71,11.53A1,1,0,1,0,5.29,13l3.84,3.84a1,1,0,0,0,1.42,0l8.16-8.16A1,1,0,0,0,18.71,7.21Z"
                            ></path>
                        </svg>
                    ) : dataSearchOkpd2?.is_rf_quota ? (
                        <svg
                            className="w-5 h-5"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            id="check"
                        >
                            <path
                                fill="#187A33"
                                d="M18.71,7.21a1,1,0,0,0-1.42,0L9.84,14.67,6.71,11.53A1,1,0,1,0,5.29,13l3.84,3.84a1,1,0,0,0,1.42,0l8.16-8.16A1,1,0,0,0,18.71,7.21Z"
                            ></path>
                        </svg>
                    ) : (
                        ''
                    )}
                </div>
                <div
                    className=" flex w-6 h-5 items-center justify-center"
                    title="Закупка только в электронной форме"
                >
                    {dataSearchOkpd2?.is_electronic == true ? (
                        <svg
                            className="w-5 h-5"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            id="check"
                        >
                            <path
                                fill="#187A33"
                                d="M18.71,7.21a1,1,0,0,0-1.42,0L9.84,14.67,6.71,11.53A1,1,0,1,0,5.29,13l3.84,3.84a1,1,0,0,0,1.42,0l8.16-8.16A1,1,0,0,0,18.71,7.21Z"
                            ></path>
                        </svg>
                    ) : (
                        ''
                    )}
                </div>
            </div>

            <div className="flex flex-row h-8 w-8 items-center justify-center text-center">
                <button
                    onClick={(e) => onDeleteClick()}
                    className=" flex w-6 h-6 rounded-full items-center justify-center hover:bg-[#701F1F]"
                >
                    <svg
                        className="w-3 h-3"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        id="trash-alt"
                    >
                        <path
                            fill="#FFFFFF"
                            d="M10,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,10,18ZM20,6H16V5a3,3,0,0,0-3-3H11A3,3,0,0,0,8,5V6H4A1,1,0,0,0,4,8H5V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V8h1a1,1,0,0,0,0-2ZM10,5a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1V6H10Zm7,14a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V8H17Zm-3-1a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,14,18Z"
                        ></path>
                    </svg>
                </button>
            </div>
        </div>
    )
}

export default PurchaseItemsTableRow
