import React, { useEffect, useState } from 'react'
import { useLoaderData, useSearchParams, useParams } from 'react-router-dom'
import { useAuth } from '../../../../../hooks/auth'
import {
    Items,
    LongTermVolumes,
    PlanPosition,
    PositionFinancialProvisionVolumes,
} from '../types'

import { Plan } from '../../types'
import PurchaseBasicInfo from './components/PurchaseBasicInfo'
import PurchaseContractInfo from './components/PurchaseContractInfo'
import PurchaseBudgetFunds from './components/PurchaseBudgetFunds'
import PurchaseDatesInfo from './components/PurchaseDatesInfo'
import PurchaseItemsSection from './components/PurchaseItemsSection'
import PurchaseMethod from './components/PurchaseMethod'
import PurchasePaymentBreakdown from './components/PurchasePaymentBreakdown'

import Footer from './Footer'
import PurchaseChangeInfo from './components/PurchaseChangeInfo'
import { Structure } from '../../../Org/Structure/types'
import { Role } from '../../../Org/Roles/types'
import { Approvals, Approvers, ApproversAdd } from './types'
import { User } from '../../../Org/Users/types'
// import ApprovalsList from './components/Approval/ApprovalsList'

type ApiResponse = {
    message: User[]
}

type ApiResponsePlan = {
    message: Plan[]
}

export const loadPlanPosition = async ({
    params,
}: any): Promise<PlanPosition | null> => {
    const action = params.action
    const isDraft =
        action === 'new' || action === 'draft' || action === 'approve'

    let url = process.env.REACT_APP_API_URL + '/api/backend/v1/'

    if (isDraft) {
        if (action == 'approve') {
            url += 'plan_position_draft_for_approve'
        } else {
            url += 'plan_position_draft'
        }
    } else {
        url += 'plan_positions'
    }

    url += '/?plan_guid=' + params.plan_guid

    if (action !== 'new') {
        url += '&guid=' + params.pos_guid
    }

    return fetch(url, {
        method: action === 'new' ? 'POST' : 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then((response) => {
            if (response.ok) {
                return response.json()
            } else {
                throw new Error('Failed to fetch plan positions')
            }
        })
        .then(
            (data) =>
                (isDraft
                    ? action === 'new'
                        ? data.plan_pos
                        : data
                    : data[0]) as Promise<PlanPosition>
        )
        .catch(() => null)
}

function getCurrentYear() {
    const today = new Date()
    return today.getFullYear() // Возвращает текущий год
}

function getCurrentMonth() {
    const today = new Date()
    return today.getMonth() // Возвращает номер месяца (0–11)
}

function getQuarterByMonth(month: number) {
    return Math.ceil(month / 3)
}

function getStartDate(pos: PlanPosition): Date {
    let date: Date
    if (pos.purchase_planned_date !== null) {
        date = new Date(pos.purchase_planned_date)
    } else if (
        pos.purchase_period_year !== null &&
        pos.purchase_period_month !== null
    ) {
        date = new Date(pos.purchase_period_year, pos.purchase_period_month - 1)
    } else {
        date = new Date()
    }
    return date
}

function getUpdatedLongTermVolumes(
    pos: PlanPosition,
    existingVolumes: LongTermVolumes | null,
    yearsInRange: number[]
): LongTermVolumes {
    if (existingVolumes === null) {
        return {
            volume: 0,
            currency: {
                code: pos.currency_code,
                digital_code: pos.currency_digital_code,
                name: pos.currency_name,
            },
            details: {
                items: yearsInRange.map((year) => ({
                    summ: 0,
                    year: year,
                })),
            },
            volume_rub: undefined,
            exchange_rate: null,
            exchange_rate_date: null,
        }
    } else {
        const existingYears = existingVolumes.details.items.map(
            (item) => item.year
        )

        const newItems = yearsInRange
            .filter((year) => !existingYears.includes(year))
            .map((year) => ({
                summ: 0,
                year: year,
            }))

        const existingItems = existingVolumes.details.items.filter((item) =>
            yearsInRange.includes(item.year)
        )

        const allItems = [...newItems, ...existingItems].sort(
            (a, b) => a.year - b.year
        )

        const volume = existingItems
            .map((item) => item.summ)
            .reduce((a, b) => Math.round((a + b) * 100) / 100, 0)

        return {
            volume: volume,
            currency: {
                code: pos.currency_code,
                digital_code: pos.currency_digital_code,
                name: pos.currency_name,
            },
            details: {
                items: allItems,
            },
            volume_rub: existingVolumes.volume_rub,
            exchange_rate: existingVolumes.exchange_rate,
            exchange_rate_date: existingVolumes.exchange_rate_date,
        }
    }
}

function onDateUpdate(
    pos: PlanPosition,
    isStart: boolean,
    newDate: Date,
    isFullDate?: boolean
): PlanPosition {
    const getYearsInRange = (start: Date, end: Date) => {
        const startYear = start.getFullYear()
        const endYear = end.getFullYear()
        return Array.from(
            { length: endYear - startYear + 1 },
            (_, i) => startYear + i
        )
    }

    const yearsInRange = getYearsInRange(
        isStart ? newDate : getStartDate(pos),
        isStart ? new Date(pos.contract_end_date) : newDate
    )

    const newLongTermVolumes =
        yearsInRange.length > 1
            ? getUpdatedLongTermVolumes(
                  pos,
                  pos.long_term_volumes,
                  yearsInRange
              )
            : null
    const newLongTermSmbVolumes =
        yearsInRange.length > 1
            ? getUpdatedLongTermVolumes(
                  pos,
                  pos.long_term_smb_volumes,
                  yearsInRange
              )
            : null

    return {
        ...pos,
        purchase_planned_date: isStart
            ? isFullDate
                ? `${newDate.getFullYear()}-${String(newDate.getMonth() + 1).padStart(2, '0')}-${String(newDate.getDate()).padStart(2, '0')}`
                : null
            : pos.purchase_planned_date,
        purchase_period_month: isStart
            ? isFullDate
                ? null
                : newDate.getMonth() + 1
            : pos.purchase_period_month,
        purchase_period_year: isStart
            ? isFullDate
                ? null
                : newDate.getFullYear()
            : pos.purchase_period_year,
        purchase_period_quarter: isStart
            ? isFullDate
                ? null
                : getQuarterByMonth(newDate.getMonth() + 1)
            : pos.purchase_period_quarter,
        contract_end_date: isStart
            ? pos.contract_end_date
            : `${newDate.getFullYear()}-${String(newDate.getMonth() + 1).padStart(2, '0')}-${String(newDate.getDate()).padStart(2, '0')}`,
        long_term_volumes: newLongTermVolumes,
        long_term_smb_volumes: newLongTermSmbVolumes,
    }
}

function onCurrencyUpdate(
    pos: PlanPosition,
    currencyCode: number
): Partial<PlanPosition> {
    const newPos = {
        currency_digital_code: currencyCode,
    } as Partial<PlanPosition>

    if (currencyCode === 643) {
        Object.assign(newPos, {
            currency_code: 'RUB',
            currency_name: 'Российский рубль',
            exchange_rate: null,
            exchange_rate_date: null,
        })
    } else {
        if (currencyCode === 840) {
            Object.assign(newPos, {
                currency_code: 'USD',
                currency_name: 'Доллар США',
            })
        } else if (currencyCode === 978) {
            Object.assign(newPos, {
                currency_code: 'EUR',
                currency_name: 'Евро',
            })
        } else if (currencyCode === 156) {
            Object.assign(newPos, {
                currency_code: 'CNY',
                currency_name: 'Китайский юань',
            })
        }
        Object.assign(newPos, {
            exchange_rate: 1,
            exchange_rate_date: new Date().toISOString().slice(0, 10),
        })
    }

    return newPos
}

const getStructureList = async (): Promise<Structure[]> => {
    return fetch(
        process.env.REACT_APP_API_URL + '/api/backend/v1/organization_struct',
        {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        }
    )
        .then((response) => {
            if (response.ok) {
                return response.json()
            } else {
                throw new Error('Ошибка запроса на способы закупок')
            }
        })
        .catch((e) => {
            console.error(e)
        })
}

const getRoleList = async (): Promise<Role[]> => {
    return fetch(
        process.env.REACT_APP_API_URL + '/api/backend/v1/organizations/roles/',
        {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        }
    )
        .then(async (response) => {
            if (response.ok) {
                const data = await response.json()
                return data.result // Достаём массив из result
            } else {
                throw new Error('Ошибка запроса на роли')
            }
        })
        .catch((e) => {
            console.error('Ошибка:', e)
            return [] // Возвращаем пустой массив в случае ошибки
        })
}

const getUserList = async (): Promise<User[]> => {
    return fetch(
        process.env.REACT_APP_API_URL + '/api/backend/v1/organization/users',
        {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        }
    )
        .then((response) => {
            if (response.ok) {
                return response.json()
            } else {
                throw new Error('Failed to fetch organization')
            }
        })
        .then((response: ApiResponse) => response.message)
        .catch((e) => {
            console.error(e)
            return []
        })
}

const getCurrentDate = (): string => {
    const today = new Date()
    const year = today.getFullYear()
    const month = String(today.getMonth() + 1).padStart(2, '0') // добавляем 1, так как месяцы начинаются с 0
    const day = String(today.getDate()).padStart(2, '0')
    return `${year}-${month}-${day}`
}

const getCurrentDateTime = (): string => {
    const today = new Date()
    const year = today.getFullYear()
    const month = String(today.getMonth() + 1).padStart(2, '0') // Добавляем 1, так как месяцы начинаются с 0
    const day = String(today.getDate()).padStart(2, '0')
    const hours = String(today.getHours()).padStart(2, '0') // Часы
    const minutes = String(today.getMinutes()).padStart(2, '0') // Минуты
    const seconds = String(today.getSeconds()).padStart(2, '0') // Секунды
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
}

const PlanPositionEditPage: React.FC = () => {
    const [pos, setPos] = useState(useLoaderData() as PlanPosition)
    const { user } = useAuth()

    const [loading, setLoading] = useState<boolean>(false)
    const [creating, setCreating] = useState<boolean>(false)
    const [deleting, setDeleting] = useState<boolean>(false)

    const { plan_guid: routePlanGuid } = useParams()
    const [planGuidNav, setPlanGuidNav] = useState(routePlanGuid)

    const [isLoadingPlan, setLoadingPlan] = useState<boolean>(true)
    const [plan, setPlan] = useState<Plan[]>([])
    const [isSendPlan, setSendPlan] = useState<boolean>(false)

    const [isUserStructGuid, setUserStructGuid] = useState('')
    const [isUserStructName, setUserStructName] = useState('')
    const [isUserRoleGuid, setUserRoleGuid] = useState('')
    const [isUserRoleName, setUserRoleName] = useState('')

    const [structList, setStructList] = useState<Structure[]>([])

    const [approvals, setApprovals] = useState<Approvals[]>([])
    const [expandedIndex, setExpandedIndex] = useState<number | null>(null)

    const [approvers, setApprovers] = useState<Approvers[]>([])
    const [userApprovers, setUserApprovers] = useState<Approvers[]>([])

    const [saveLoading, setSaveLoading] = useState<boolean>(false)
    const [selectedStatus, setSelectedStatus] = useState<number | null>(null)
    const [comment, setComment] = useState<string>('')

    const [approvalCreatePopup, setApprovalCreatePopup] = useState(false)
    const [isApprovalCreate, setApprovalCreate] = useState(false) // Состояние для анимации загрузки

    const [approvalStartDate, setApprovalStartDate] = useState(getCurrentDate())
    const [approvalEndDate, setApprovalEndDate] = useState('')
    const [approvalPriority, setApprovalPriority] = useState(0)
    const [approvalComment, setApprovalComment] = useState('')

    const [userList, setUserList] = useState<User[]>([])
    const [userFilteredList, setUserFilteredList] = useState<User[]>([])
    const [addApproversPopup, setAddApproversPopup] = useState(false)
    const [isApprovalsAdd, setApprovalAdd] = useState(false) // Состояние для анимации загрузки

    const [originalUserList, setOriginalUserList] = useState<User[]>([]) // Сохраняем исходный список пользователей

    const [approversAdd, setApproversAdd] = useState<ApproversAdd[]>([])

    const [selectedItems, setSelectedItems] = useState<string[]>([])

    const [userFilter, setUserFilter] = useState<string[]>([]) // Массив строк

    const [changeApprovalsPopup, setChangeApprovalsPopup] = useState(false)
    const [isApprovalChanging, setApprovalChanging] = useState(false) // Состояние для анимации загрузки
    const [approvalChangeGuid, setApprovalChangeGuid] = useState('')
    const [approvalAddGuid, setApprovalAddGuid] = useState('')

    const isNew = location.hash.includes('new')

    const isApprove = location.hash.includes('approve')

    const [userApprover, setUserApprover] = useState<Approvers>()

    const getNameStruct = (struct_guid: string) => {
        const nameSctruct = structList.find((s) => s.guid === struct_guid)
        return nameSctruct?.struct_short_name || '-'
    }

    const getNameUser = (user_guid: string) => {
        const nameUser = userList.find((u) => u.guid === user_guid)
        return (
            `${nameUser?.last_name} ${nameUser?.first_name} ${nameUser?.patronymic}` ||
            '-'
        )
    }

    const loadPlan = () => {
        setLoadingPlan(true)

        fetch(process.env.REACT_APP_API_URL + '/api/backend/v1/plans', {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((response) => {
                if (response.ok) {
                    return response.json()
                } else {
                    throw new Error('Failed to fetch plan')
                }
            })
            .then((response: ApiResponsePlan) => response.message)
            .catch((e) => {
                console.error(e)
                return []
            })
            .then((response) => {
                setPlan(
                    response.filter((plans: Plan) => plans.guid === planGuidNav)
                )

                const planData = response.filter(
                    (plans: Plan) => plans.guid === planGuidNav
                )
                planData.map((item: Plan) => setSendPlan(item.is_sent))
            })
            .finally(() => {
                setLoadingPlan(false)
            })
    }

    useEffect(() => {
        // Синхронизируйте локальное состояние с маршрутом
        setPlanGuidNav(routePlanGuid)
    }, [routePlanGuid])

    const getApprovals = async (pos_guid: string): Promise<Approvals[]> => {
        return fetch(
            process.env.REACT_APP_API_URL +
                `/api/backend/v1/approvals_by_pos_draft_guid?plan_pos_guid=${pos_guid}`,
            {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        )
            .then((response) => {
                if (response.ok) {
                    return response.json()
                } else {
                    throw new Error('Ошибка запроса по согласованию')
                }
            })
            .catch((e) => {
                console.error(e)
            })
    }

    const getApprovers = async (approvalGuid: string): Promise<Approvers[]> => {
        return fetch(
            process.env.REACT_APP_API_URL +
                `/api/backend/v1/approvers_by_approval/${approvalGuid}`,
            {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        )
            .then((response) => {
                if (response.ok) {
                    return response.json()
                } else {
                    throw new Error('Ошибка запроса по согласующим')
                }
            })
            .catch((e) => {
                console.error(e)
            })
    }

    const updateApproverSatatus = async (
        approval_guid: string,
        approver_guid: string
    ) => {
        try {
            const response = await fetch(
                process.env.REACT_APP_API_URL +
                    `/api/backend/v1/update_db_approver_is_saw?approver_guid=${approver_guid}`,
                {
                    method: 'PUT',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            )
            if (!response.ok) {
                throw new Error('Обновления просмотра согласующим')
            }
            console.log('Просмотр обновлен')
        } catch (error) {
            console.error('Ошибка при обновлении просмотра согласующим:', error)
        } finally {
            const updatedApprovers = await getApprovers(approval_guid)
            setApprovers(updatedApprovers)
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const roles = await getRoleList()
                if (Array.isArray(roles)) {
                    const matchingRole = roles.find(
                        (role) =>
                            role.guid.toLowerCase() === user?.object.role_id
                    )
                    setUserRoleName(matchingRole?.name || '')
                    setUserRoleGuid(matchingRole?.guid || '')
                } else {
                    console.error('Роли не являются массивом:', roles)
                }

                const users = await getUserList()
                if (Array.isArray(users)) {
                    setOriginalUserList(users) // Сохраняем исходный список пользователей
                    setUserList(users)

                    // Начальная фильтрация userFilteredList
                    const approversGuids = new Set(
                        approversAdd.map((approver) => approver.user_guid)
                    )
                    const filteredUsers = users.filter(
                        (u) => !approversGuids.has(String(u.guid))
                    )
                    setUserFilteredList(filteredUsers)
                } else {
                    console.error('Нет данных:', users)
                }

                const structs = await getStructureList()
                if (Array.isArray(structs)) {
                    setStructList(structs)
                } else {
                    console.error('Нет данных:', structs)
                }

                setSelectedItems([])

                loadPlan()
            } catch (error) {
                console.error('Ошибка при загрузке данных:', error)
            }
        }
        fetchData()
    }, [])

    // Второй useEffect для обновления фильтров при изменении approversAdd
    useEffect(() => {
        const approversGuids = new Set(
            approversAdd.map((approver) => approver.user_guid)
        )
        const filteredUsers = originalUserList.filter(
            (u) => !approversGuids.has(String(u.guid))
        ) // Используем оригинальный список для фильтрации
        setUserFilteredList(filteredUsers)
    }, [approversAdd, originalUserList]) // Зависимости

    const handleInputChange = (value: string) => {
        setUserFilter([value])
    }

    useEffect(() => {
        const updateFilteredList = () => {
            // Получаем список уже добавленных пользователей
            const approversGuids = new Set(
                approversAdd.map((approver) => approver.user_guid)
            )

            // Фильтруем исходный список, исключая уже выбранных
            let filtered = originalUserList.filter(
                (u) => !approversGuids.has(String(u.guid))
            )

            // Если активен текстовый фильтр, дополнительно фильтруем по тексту
            if (userFilter.length > 0) {
                filtered = filtered.filter((u) =>
                    userFilter.some(
                        (filter) =>
                            u.last_name
                                ?.toLowerCase()
                                .includes(filter.toLowerCase()) ||
                            u.first_name
                                ?.toLowerCase()
                                .includes(filter.toLowerCase()) ||
                            u.patronymic
                                ?.toLowerCase()
                                .includes(filter.toLowerCase())
                    )
                )
            }

            // Обновляем состояние отфильтрованных пользователей
            setUserFilteredList(filtered)
        }

        updateFilteredList()
    }, [approversAdd, originalUserList, userFilter]) // Зависимости

    // Функция для обработки изменения состояния отдельного checkbox
    const handleCheckboxChange = (user_guid: string) => {
        setSelectedItems((prevSelectedItems) => {
            if (prevSelectedItems.includes(user_guid)) {
                return prevSelectedItems.filter(
                    (itemId) => itemId !== user_guid
                )
            } else {
                return [...prevSelectedItems, user_guid]
            }
        })
    }

    const handleSave = () => {
        if (pos.currency_code == 'RUB') {
            pos.maximum_contract_price_rub = null
        } else if (pos.exchange_rate !== null) {
            const rub = Number(pos.maximum_contract_price) * pos.exchange_rate
            pos.maximum_contract_price_rub = rub.toString()
        }

        if (pos.long_term_volumes !== null) {
            pos.long_term_volumes.currency.code = pos.currency_code
            pos.long_term_volumes.currency.digital_code =
                pos.currency_digital_code
            pos.long_term_volumes.currency.name = pos.currency_name
        }

        if (pos.long_term_smb_volumes !== null) {
            pos.long_term_smb_volumes.currency.code = pos.currency_code
            pos.long_term_smb_volumes.currency.digital_code =
                pos.currency_digital_code
            pos.long_term_smb_volumes.currency.name = pos.currency_name
        }

        fetch(
            process.env.REACT_APP_API_URL +
                '/api/backend/v1/plan_position_draft/?plan_guid=' +
                encodeURIComponent(pos.plan_guid) +
                '&guid=' +
                encodeURIComponent(pos.guid),
            {
                method: 'PUT',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(pos),
            }
        )
            .then((response) => {
                if (response.ok) {
                    // success
                    return response.json()
                } else {
                    throw new Error('Неверный почтовый адрес или пароль')
                }
            })
            .catch((error) => undefined)
            .finally(() => undefined)
    }

    let purchasePlannedDate: Date
    if (pos.purchase_planned_date !== null) {
        purchasePlannedDate = new Date(pos.purchase_planned_date)
    } else if (
        pos.purchase_period_year !== null &&
        pos.purchase_period_month !== null
    ) {
        purchasePlannedDate = new Date(
            pos.purchase_period_year,
            pos.purchase_period_month - 1
        )
    } else {
        purchasePlannedDate = new Date()
    }

    let contractEndDate: Date
    if (pos.contract_end_date !== null) {
        contractEndDate = new Date(pos.contract_end_date)
    } else {
        contractEndDate = new Date(getCurrentYear(), getCurrentMonth())
    }

    useEffect(() => {
        if (pos.purchase_planned_date !== null) {
            setPos((prev) => ({
                ...prev,
                purchase_planned_date: `${purchasePlannedDate.getFullYear()}-${String(purchasePlannedDate.getMonth() + 1).padStart(2, '0')}-${String(purchasePlannedDate.getDate()).padStart(2, '0')}`,
            }))
        } else {
            setPos((prev) => ({
                ...prev,
                purchase_period_year: purchasePlannedDate.getFullYear(),
                purchase_period_month: purchasePlannedDate.getMonth() + 1,
            }))
        }
        setPos((prev) => ({
            ...prev,
            contract_end_date: `${contractEndDate.getFullYear()}-${String(contractEndDate.getMonth() + 1).padStart(2, '0')}-${String(contractEndDate.getDate()).padStart(2, '0')}`,
        }))

        setPos((prev) => ({
            ...prev,
            purchase_period_quarter: getQuarterByMonth(
                purchasePlannedDate.getMonth() + 1
            ),
        }))

        if (isNew) {
            const fetchData = async () => {
                const structs = await getStructureList()
                if (structs) {
                    const matchingStruct = structs.find(
                        (struct) =>
                            struct.guid.toLowerCase() === user?.object.struct_id
                    )

                    const structShortName =
                        matchingStruct?.struct_short_name || ''
                    setUserStructName(structShortName)
                    const structGuid = matchingStruct?.guid || ''
                    setUserStructGuid(structGuid)

                    setPos((prev) => ({
                        ...prev,
                        struct_guid: prev.struct_guid ?? structGuid,
                        struct_name: prev.struct_name ?? structShortName,
                    }))
                }
            }
            fetchData()
        }
    }, [])

    const handleCurrencyExchangeRateChange = (newExchangeRate: number | 0) => {
        setPos((prev) => {
            const updatedLongTermVolumes = prev.long_term_volumes
                ? {
                      ...prev.long_term_volumes,
                      details: {
                          items: prev.long_term_volumes.details.items.map(
                              (item) => ({
                                  ...item,
                                  summ_rub:
                                      prev.currency_digital_code !== 643
                                          ? Math.round(
                                                item.summ *
                                                    (newExchangeRate || 1) *
                                                    100
                                            ) / 100
                                          : undefined,
                              })
                          ),
                      },
                      volume_rub:
                          prev.currency_digital_code !== 643
                              ? Math.round(
                                    prev.long_term_volumes.volume *
                                        (newExchangeRate || 1) *
                                        100
                                ) / 100
                              : undefined,
                      exchange_rate: newExchangeRate,
                  }
                : null

            const updatedLongTermSmbVolumes = prev.long_term_smb_volumes
                ? {
                      ...prev.long_term_smb_volumes,
                      details: {
                          items: prev.long_term_smb_volumes.details.items.map(
                              (item) => ({
                                  ...item,
                                  summ_rub:
                                      prev.currency_digital_code !== 643
                                          ? Math.round(
                                                item.summ *
                                                    (newExchangeRate || 1) *
                                                    100
                                            ) / 100
                                          : undefined,
                              })
                          ),
                      },
                      volume_rub:
                          prev.currency_digital_code !== 643
                              ? Math.round(
                                    prev.long_term_smb_volumes.volume *
                                        (newExchangeRate || 1) *
                                        100
                                ) / 100
                              : undefined,
                      exchange_rate: newExchangeRate,
                  }
                : null

            return {
                ...prev,
                exchange_rate: newExchangeRate < 0 ? 0 : newExchangeRate,
                long_term_volumes: updatedLongTermVolumes,
                long_term_smb_volumes: updatedLongTermSmbVolumes,
            }
        })
    }

    const handleCurrencyExchangeDateChange = (newExchangeRateDate: Date) => {
        setPos((prev) => {
            const updatedLongTermVolumes = prev.long_term_volumes
                ? {
                      ...prev.long_term_volumes,
                      exchange_rate_date: newExchangeRateDate
                          .toISOString()
                          .slice(0, 10),
                  }
                : null

            const updatedLongTermSmbVolumes = prev.long_term_smb_volumes
                ? {
                      ...prev.long_term_smb_volumes,
                      exchange_rate_date: newExchangeRateDate
                          .toISOString()
                          .slice(0, 10),
                  }
                : null

            return {
                ...prev,
                exchange_rate_date: newExchangeRateDate
                    .toISOString()
                    .slice(0, 10),
                long_term_volumes: updatedLongTermVolumes,
                long_term_smb_volumes: updatedLongTermSmbVolumes,
            }
        })
    }

    // Обработчик изменения наличия бюджетных средств
    const handleBudgetFundsChange = (value: boolean) => {
        setPos((prev) => ({
            ...prev,
            maximum_contract_price_from_budget: value,
        }))
    }

    // Обработчик изменения данных в таблице
    const handlePositionFinancialProvisionChange = (updatedItems: Items[]) => {
        setPos((prev) => ({
            ...prev,
            position_financial_provision_volumes: {
                ...prev.position_financial_provision_volumes,
                items: updatedItems,
            },
        }))
    }

    const handleApprovalPopup = () => {
        setApprovalCreatePopup(!approvalCreatePopup)
    }

    const saveApprovalResult = async (
        approverGuid: string,
        status: number,
        comments: string
    ) => {
        try {
            setSaveLoading(true)
            const currentDate = new Date().toISOString().split('T')[0] // Формат YYYY-MM-DD

            const response = await fetch(
                process.env.REACT_APP_API_URL +
                    `/api/backend/v1/approvers/${approverGuid}`,
                {
                    method: 'PUT',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        approve_date: currentDate,
                        status: status,
                        comment: comments,
                    }),
                }
            )

            if (!response.ok) {
                throw new Error('Ошибка сохранения результата согласования')
            }

            // Обновляем данные согласующего в массиве approvers
            setApprovers((prevApprovers) =>
                prevApprovers.map((approver) =>
                    approver.guid === approverGuid
                        ? {
                              ...approver,
                              approve_date: currentDate,
                              status: status,
                              comment: comments,
                          }
                        : approver
                )
            )

            // Заново загружаем approvals
            const updatedApprovals = await getApprovals(pos.guid)
            setApprovals(updatedApprovals)

            // Заново загружаем approvers для текущего approval
            const currentApprovalGuid = approvals.find(
                (approval) =>
                    approval.guid ===
                    approvers.find((a) => a.guid === approverGuid)
                        ?.approval_guid
            )?.guid

            if (currentApprovalGuid) {
                const updatedApprovers = await getApprovers(currentApprovalGuid)
                setApprovers(updatedApprovers)

                // Обновляем userApprovers
                const excludedApprover = updatedApprovers.filter(
                    (approver) => approver.user_guid === user?.object.user_id
                )
                setUserApprovers(excludedApprover)
            }
        } catch (error) {
            console.error(error)
        } finally {
            setSaveLoading(false)
        }
    }

    useEffect(() => {
        const fetchApprovals = async () => {
            const data = await getApprovals(pos.guid)
            setApprovals(data)

            const initialIndex = data.findIndex(
                (approval) => approval.status === 0
            )
            if (initialIndex !== -1) {
                const initialApprovalGuid = data[initialIndex].guid
                setExpandedIndex(initialIndex)
                try {
                    setLoading(true)
                    const approversData =
                        await getApprovers(initialApprovalGuid)

                    // Check if is_saw is false for the user-specific approvers
                    for (const approver of approversData) {
                        if (approver.user_guid === user?.object.user_id) {
                            if (
                                approver.is_saw === false ||
                                approver.is_saw === null
                            ) {
                                // Run updateApproverSatatus if is_saw is false
                                await updateApproverSatatus(
                                    initialApprovalGuid,
                                    approver.guid
                                )
                            }
                        }
                    }

                    // Re-fetch approvers data after update
                    const updatedApprovers =
                        await getApprovers(initialApprovalGuid)
                    setApprovers(updatedApprovers)

                    // Filter user-specific approvers
                    const excludedApprover = approversData.filter(
                        (approver) =>
                            approver.user_guid === user?.object.user_id
                    )
                    setUserApprovers(excludedApprover)
                } catch (error) {
                    console.error(error)
                } finally {
                    setLoading(false)
                }
            }
        }
        fetchApprovals()
    }, [])

    const toggleAccordion = async (index: number, approvalGuid: string) => {
        if (expandedIndex === index) {
            setExpandedIndex(null)
            return
        }

        try {
            setLoading(true)
            const data = await getApprovers(approvalGuid)

            // Store all approvers as is
            setApprovers(data)

            // Filter user-specific approvers
            const excludedApprover = data.filter(
                (approver) => approver.user_guid === user?.object.user_id
            )
            setUserApprovers(excludedApprover)
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }

        setExpandedIndex(index)
    }

    const createApprovers = async (
        approver: ApproversAdd,
        approvalGuid: string
    ) => {
        setApprovalCreate(true) // Включаем анимацию загрузки
        try {
            const response = await fetch(
                process.env.REACT_APP_API_URL + `/api/backend/v1/approvers`,
                {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        ...approver,
                        approval_guid: approvalGuid, // Добавляем guid созданного Approval
                    }),
                }
            )
            if (!response.ok) {
                throw new Error('Ошибка при добавлении согласующего')
            }
            console.log('Согласующий добавлен')
        } catch (error) {
            console.error('Ошибка при добавлении согласующего:', error)
        } finally {
            setApprovalCreate(false) // Отключаем анимацию загрузки
        }
    }

    const deleteApprovers = async (approver_guid: string) => {
        setApprovalCreate(true) // Включаем анимацию загрузки
        try {
            const response = await fetch(
                process.env.REACT_APP_API_URL +
                    `/api/backend/v1/approvers/${approver_guid}`,
                {
                    method: 'DELETE',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            )
            if (!response.ok) {
                throw new Error('Ошибка при удалении согласующего')
            }

            // Заново загружаем approvers для созданного approval
            const updatedApprovers = await getApprovers(approvalChangeGuid)
            setApprovers(updatedApprovers) // Обновляем список согласующих

            console.log('Согласующий удален')
        } catch (error) {
            console.error('Ошибка при удалении согласующего:', error)
        } finally {
            setApprovalCreate(false) // Отключаем анимацию загрузки
        }
    }

    const getApprovalsForChange = async (
        approvalGuid: string
    ): Promise<Approvals> => {
        return fetch(
            process.env.REACT_APP_API_URL +
                `/api/backend/v1/approvals/${approvalGuid}`,
            {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        )
            .then((response) => {
                if (response.ok) {
                    return response.json()
                } else {
                    throw new Error('Ошибка запроса по согласующим')
                }
            })
            .catch((e) => {
                console.error(e)
            })
    }

    const updateApprovers = async (
        approver: ApproversAdd,
        approvalGuid: string
    ) => {
        setApprovalCreate(true) // Включаем анимацию загрузки
        try {
            const response = await fetch(
                process.env.REACT_APP_API_URL +
                    `/api/backend/v1/approvers/${approver.guid}`,
                {
                    method: 'PUT',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        ...approver,
                        approval_guid: approvalGuid, // Добавляем guid созданного Approval
                    }),
                }
            )
            if (!response.ok) {
                throw new Error('Ошибка при добавлении согласующего')
            }
            console.log('Согласующий добавлен')
        } catch (error) {
            console.error('Ошибка при добавлении согласующего:', error)
        } finally {
            setApprovalCreate(false) // Отключаем анимацию загрузки
        }
    }

    const updateApproval = async (approvalGuid: string) => {
        setApprovalChanging(true) // Включаем анимацию загрузки
        try {
            // Шаг 2. Получаем список существующих согласующих для данного approval
            const existingApprovers = await getApprovers(approvalGuid)
            const existingApproverGuids = new Set(
                existingApprovers.map((item) => item.user_guid)
            )

            // Шаг 3. Добавляем только новых согласующих
            for (const approver of approversAdd) {
                if (!existingApproverGuids.has(approver.user_guid)) {
                    await createApprovers(approver, approvalGuid)
                }
            }
            setExpandedIndex(null)

            // Шаг 1. Обновляем данные approval
            const response = await fetch(
                process.env.REACT_APP_API_URL +
                    `/api/backend/v1/approvals/${approvalGuid}`,
                {
                    method: 'PUT',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        start_date: approvalStartDate,
                        end_date: approvalEndDate,
                        priority: approvalPriority,
                        comment: approvalComment,
                    }),
                }
            )

            if (!response.ok) {
                throw new Error('Ошибка при обновлении согласования')
            }

            console.log('Данные согласования успешно обновлены')

            // Шаг 4. Заново загружаем approvals
            const updatedApprovals = await getApprovals(pos.guid)
            setApprovals(updatedApprovals)

            // Шаг 5. Заново загружаем approvers для обновленного approval
            const updatedApprovers = await getApprovers(approvalGuid)
            setApprovers(updatedApprovers)

            setApprovalCreatePopup(false)

            console.log('Согласующие успешно обновлены или добавлены')
        } catch (error) {
            console.error(
                'Ошибка при обновлении согласования или согласующих:',
                error
            )
        } finally {
            setApprovalChanging(false) // Отключаем анимацию загрузки
        }
    }

    useEffect(() => {
        const updateDataForChange = async () => {
            if (approvalChangeGuid) {
                try {
                    const updatedApproval =
                        await getApprovalsForChange(approvalChangeGuid)

                    console.log(
                        'Результат getApprovalsForChange:',
                        updatedApproval
                    )

                    if (
                        updatedApproval &&
                        typeof updatedApproval === 'object' &&
                        !Array.isArray(updatedApproval)
                    ) {
                        // Обрабатываем как объект
                        setApprovalStartDate(updatedApproval.start_date)
                        setApprovalEndDate(updatedApproval.end_date)
                        setApprovalPriority(updatedApproval.priority)
                        setApprovalComment(updatedApproval.comment || '')
                    } else {
                        console.error(
                            'Ошибка: Неподдерживаемый формат ответа',
                            updatedApproval
                        )
                    }

                    const updatedApprovers =
                        await getApprovers(approvalChangeGuid)
                    setApproversAdd(updatedApprovers)
                } catch (error) {
                    console.error('Ошибка при обновлении данных:', error)
                }
            }
        }
        updateDataForChange()
    }, [approvalChangeGuid])

    useEffect(() => {
        const updateDataForAdd = async () => {
            if (approvalAddGuid) {
                try {
                    const updatedApproval =
                        await getApprovalsForChange(approvalAddGuid)

                    console.log(
                        'Результат getApprovalsForAdd:',
                        updatedApproval
                    )

                    if (
                        updatedApproval &&
                        typeof updatedApproval === 'object' &&
                        !Array.isArray(updatedApproval)
                    ) {
                        // Обрабатываем как объект
                        setApprovalStartDate(updatedApproval.start_date)
                        setApprovalEndDate(updatedApproval.end_date)
                        setApprovalPriority(updatedApproval.priority)
                        setApprovalComment(updatedApproval.comment || '')
                    } else {
                        console.error(
                            'Ошибка: Неподдерживаемый формат ответа',
                            updatedApproval
                        )
                    }

                    const updatedApprovers = await getApprovers(approvalAddGuid)
                    setApproversAdd(updatedApprovers)
                } catch (error) {
                    console.error('Ошибка при обновлении данных:', error)
                }
            }
        }
        updateDataForAdd()
    }, [approvalAddGuid])

    const createApproval = async () => {
        setApprovalCreate(true) // Включаем анимацию загрузки
        try {
            const response = await fetch(
                process.env.REACT_APP_API_URL + `/api/backend/v1/approvals`,
                {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        start_date: approvalStartDate,
                        end_date: approvalEndDate,
                        priority: approvalPriority,
                        comment: approvalComment,
                        status: 0,
                        plan_pos_guid: pos.guid,
                        create_date: getCurrentDateTime(),
                    }),
                }
            )
            if (!response.ok) {
                throw new Error('Ошибка при создании согласования')
            }

            setExpandedIndex(null)

            // Получаем данные созданного approval
            const createdApproval = await response.json()

            // Проходим по массиву approversAdd и создаём каждого согласующего
            for (const approver of approversAdd) {
                await createApprovers(approver, createdApproval.guid)
            }

            // Заново загружаем approvals
            const updatedApprovals = await getApprovals(pos.guid)
            setApprovals(updatedApprovals)

            // Заново загружаем approvers для созданного approval
            const updatedApprovers = await getApprovers(createdApproval.guid)
            setApprovers((prev) => [...prev, ...updatedApprovers]) // Обновляем список согласующих

            setApprovalCreatePopup(false)
            console.log('Согласование создано')
        } catch (error) {
            console.error('Ошибка при создании согласования:', error)
        } finally {
            setApprovalCreate(false) // Отключаем анимацию загрузки
        }
    }

    const handleApprovalCreate = () => {
        setCreating(true)
        createApproval()
        setCreating(false)
    }

    const deleteApproval = async (approval_guid: string): Promise<void> => {
        const url =
            process.env.REACT_APP_API_URL +
            `/api/backend/v1/approvals/${approval_guid}`

        try {
            const response = await fetch(url, {
                method: 'DELETE',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
            })

            if (!response.ok) {
                throw new Error(
                    `Failed to delete approval. Status: ${response.status}`
                )
            }

            setExpandedIndex(null)

            // Заново загружаем approvals
            const updatedApprovals = await getApprovals(pos.guid)
            setApprovals(updatedApprovals)

            // Заново загружаем approvers для созданного approval
            const updatedApprovers = await getApprovers(approval_guid)
            setApprovers(updatedApprovers) // Обновляем список согласующих

            console.log('Approval deleted successfully')
        } catch (error) {
            console.error('Error while deleting approval:', error)
            setDeleting(false)
        } finally {
        }
    }

    const handleApprovalDelete = (approval_guid: string) => {
        setDeleting(true)
        deleteApproval(approval_guid)
        setDeleting(false)
    }

    const handleAddApproversPopup = () => {
        setAddApproversPopup(!addApproversPopup)
        setSelectedItems([])
        setUserFilter([''])
    }

    const handleRemoveApprover = (user_guid: string) => {
        setApproversAdd((prev) =>
            prev.filter((approver) => approver.user_guid !== user_guid)
        )
    }

    const handlePreAddApproval = () => {
        const currentDate = getCurrentDate() // Получаем текущую дату один раз
        const newApprovers = selectedItems.map((u, index) => ({
            guid: null,
            approve_date: null, // Устанавливаем null
            status: 0, // Статус по умолчанию
            comment: null, // Комментарий по умолчанию
            user_position: approversAdd.length + index + 1, // Позиция: текущая длина массива + индекс + 1
            user_guid: String(u), // Уникальный идентификатор пользователя
            approval_guid: null, // По умолчанию null
            organization_guid: null,
            add_approver_date: currentDate, // Устанавливаем текущую дату
            add_user_guid: null,
            is_saw: false,
        }))

        // Обновляем массив approversAdd
        setApproversAdd((prev) => [...prev, ...newApprovers])

        // Очищаем выбранные элементы и закрываем popup
        setSelectedItems([])
        setAddApproversPopup(false)
        setUserFilter([''])
    }

    // const handleChangeApprovalPopup = async () => {}

    const handleApprovalChange = () => {
        setApprovalChanging(true)
        updateApproval(approvalChangeGuid)
        setApprovalChanging(false)
    }

    const handleApprovalAdd = () => {
        setApprovalChanging(true)
        updateApproval(approvalAddGuid)
        setApprovalChanging(false)
    }

    return (
        <div className=" flex flex-col w-full h-screen items-start justify-start ">
            <div className="flex flex-col w-full h-[200px] items-center justify-center">
                <header className="p-5 text-white">{`${pos.position_stage} - ${pos.position_status}`}</header>
            </div>

            <div className="grow flex flex-row w-full overflow-y-auto items-start justify-start gap-5 p-5">
                <div className="flex flex-col w-[1260px] gap-y-5 items-start justify-start">
                    <fieldset
                        disabled={
                            isApprove || // Закрыто для всех, независимо от роли
                            ((pos.position_status === 'Готово' ||
                                pos.position_stage != 'Редактирование') &&
                                isUserRoleName != 'admin' &&
                                isUserRoleName != 'specialist')
                        }
                    >
                        <PurchaseBasicInfo
                            planGuid={pos.plan_guid}
                            posGuid={pos.guid}
                            posStatus={pos.status}
                            structShortName={
                                pos.struct_name || isUserStructName
                            }
                            structShortGuid={
                                pos.struct_guid || isUserStructGuid
                            }
                            onStructChange={({ structName, structGuid }) => {
                                setPos((prev) => ({
                                    ...prev,
                                    struct_guid: structGuid,
                                    struct_name: structName,
                                }))
                            }}
                            expensItemsCode={pos.expense_items_name}
                            onExpensItemsChange={(name) => {
                                setPos((prev) => ({
                                    ...prev,
                                    expense_items_name: name,
                                }))
                            }}
                            typeObjectPurchasePosition={
                                pos.type_object_purchase_position
                            }
                            onTypeObjectPurchasePositionChange={(name) => {
                                setPos((prev) => ({
                                    ...prev,
                                    type_object_purchase_position: name,
                                }))
                            }}
                            proposedMethodName={pos.proposed_method_name}
                            onProposedMethodChange={(name) => {
                                setPos((prev) => ({
                                    ...prev,
                                    proposed_method_name: name,
                                }))
                            }}
                            basisSingleSupplier={
                                pos.exclusive_purchase_reason_name
                            }
                            onBasisSingleSupplierChange={(name) => {
                                setPos((prev) => ({
                                    ...prev,
                                    exclusive_purchase_reason_name: name,
                                }))
                            }}
                            annotationPurchase={pos.annotation}
                            onAnnotationPurchaseChange={(annotation) => {
                                setPos((prev) => ({
                                    ...prev,
                                    annotation: annotation,
                                }))
                            }}
                        />
                    </fieldset>

                    <fieldset
                        disabled={
                            pos.status === 'P' ||
                            pos.position_status === 'Отправлено в ЕИС' ||
                            isApprove || // Закрыто для всех, независимо от роли
                            ((pos.position_status === 'Готово' ||
                                pos.position_stage != 'Редактирование' ||
                                pos.user_guid != user?.object.user_id) &&
                                isUserRoleName != 'admin' &&
                                isUserRoleName != 'specialist')
                        }
                    >
                        <main className="grow flex flex-col gap-y-5">
                            {pos.status !== 'N' &&
                                (pos.status === 'C' ||
                                    pos.changed_GWS_and_dates ||
                                    pos.changed_NMSK_more_ten_percent ||
                                    pos.other_changes ||
                                    pos.modification_description) && (
                                    <PurchaseChangeInfo
                                        changedGWSAndDates={
                                            pos.changed_GWS_and_dates
                                        }
                                        onChangedGWSAndDatesChange={(value) => {
                                            setPos((prev) => ({
                                                ...prev,
                                                changed_GWS_and_dates: value,
                                            }))
                                        }}
                                        changedNMSKMoreTenPercent={
                                            pos.changed_NMSK_more_ten_percent
                                        }
                                        onChangedNMSKMoreTenPercentChange={(
                                            value
                                        ) => {
                                            setPos((prev) => ({
                                                ...prev,
                                                changed_NMSK_more_ten_percent:
                                                    value,
                                            }))
                                        }}
                                        otherChanges={pos.other_changes}
                                        onOtherChangesChange={(value) => {
                                            setPos((prev) => ({
                                                ...prev,
                                                other_changes: value,
                                            }))
                                        }}
                                        modificationDescription={
                                            pos.modification_description
                                        }
                                        onModificationDescriptionChange={(
                                            value
                                        ) => {
                                            setPos((prev) => ({
                                                ...prev,
                                                modification_description: value,
                                            }))
                                        }}
                                    />
                                )}

                            <PurchaseContractInfo
                                name={pos.contract_subject}
                                onNameChange={(name) => {
                                    setPos((prev) => ({
                                        ...prev,
                                        contract_subject: name,
                                    }))
                                }}
                                minRequirements={pos.minimum_requirements || ''}
                                onMinRequirementsChange={(minRequirements) => {
                                    setPos((prev) => ({
                                        ...prev,
                                        minimum_requirements: minRequirements,
                                    }))
                                }}
                                contractPriceReason={pos.order_pricing || ''}
                                onContractPriceReasonChange={(
                                    contractPriceReason
                                ) => {
                                    setPos((prev) => ({
                                        ...prev,
                                        order_pricing: contractPriceReason,
                                    }))
                                }}
                                nmcd={
                                    pos.maximum_contract_price
                                        ? Number(pos.maximum_contract_price)
                                        : 0
                                }
                                onNmcdChange={(newNmcd) => {
                                    setPos((prev) => ({
                                        ...prev,
                                        maximum_contract_price: newNmcd,
                                    }))
                                }}
                                currency={pos.currency_digital_code}
                                onCurrencyChange={(newCurrencyDigitalCode) => {
                                    setPos((prev) => ({
                                        ...prev,
                                        ...onCurrencyUpdate(
                                            prev,
                                            newCurrencyDigitalCode
                                        ),
                                    }))
                                }}
                                isNmcdWithTax={pos.is_nmcd_with_tax}
                                onNmcdWithTaxChange={(newNmcdWithTax) => {
                                    setPos((prev) => ({
                                        ...prev,
                                        is_nmcd_with_tax: newNmcdWithTax,
                                    }))
                                }}
                                currencyExchangeDate={
                                    pos.exchange_rate_date
                                        ? new Date(pos.exchange_rate_date)
                                        : undefined
                                }
                                onCurrencyExchangeDateChange={
                                    handleCurrencyExchangeDateChange
                                }
                                currencyExchangeRate={pos.exchange_rate ?? 1}
                                onCurrencyExchangeRateChange={
                                    handleCurrencyExchangeRateChange
                                }
                            />
                            <PurchaseBudgetFunds
                                isBudgetFundsIncluded={
                                    pos.maximum_contract_price_from_budget
                                }
                                onBudgetFundsIncludedChange={
                                    handleBudgetFundsChange
                                }
                                positionFinancialProvisionVolumeItems={
                                    pos.position_financial_provision_volumes
                                }
                                onPositionFinancialProvisionChange={
                                    handlePositionFinancialProvisionChange
                                }
                                isDraft={pos.position_status !== 'Размещена'}
                            />
                            <PurchaseDatesInfo
                                start={purchasePlannedDate}
                                onStartDateChange={(date, isFullDate) => {
                                    setPos((prev) =>
                                        onDateUpdate(
                                            prev,
                                            true /* isStart */,
                                            date,
                                            isFullDate
                                        )
                                    )
                                }}
                                end={contractEndDate}
                                onEndDateChange={(date) => {
                                    setPos((prev) =>
                                        onDateUpdate(
                                            prev,
                                            false /* isStart */,
                                            date
                                        )
                                    )
                                }}
                                isStartFullDate={
                                    pos.purchase_planned_date !== null
                                }
                            />

                            {pos.long_term_volumes !== null &&
                                pos.long_term_smb_volumes != null && (
                                    <div className="flex flex-row gap-x-4">
                                        <div className="basis-1/2">
                                            <PurchasePaymentBreakdown
                                                title="Информация об объемах оплаты долгосрочного договора"
                                                yearlyPayments={
                                                    pos.long_term_volumes
                                                }
                                                onYearlyPaymentsChange={(
                                                    year,
                                                    value
                                                ) => {
                                                    if (pos.long_term_volumes) {
                                                        const item =
                                                            pos.long_term_volumes.details.items.find(
                                                                (y) =>
                                                                    y.year ===
                                                                    year
                                                            )
                                                        if (item) {
                                                            setPos((prev) => {
                                                                if (
                                                                    prev.long_term_volumes ===
                                                                    null
                                                                ) {
                                                                    return prev
                                                                }

                                                                // Округляем newVolume, чтобы избежать ошибок с плавающей точкой
                                                                const newVolume =
                                                                    Math.round(
                                                                        (prev
                                                                            .long_term_volumes
                                                                            .volume -
                                                                            item.summ +
                                                                            value) *
                                                                            100
                                                                    ) / 100

                                                                const updatedItems =
                                                                    prev.long_term_volumes.details.items.map(
                                                                        (
                                                                            dt
                                                                        ) => {
                                                                            if (
                                                                                dt.year ===
                                                                                year
                                                                            ) {
                                                                                // Округляем summRub для предотвращения ошибок при пересчёте суммы в рубли
                                                                                const summRub =
                                                                                    prev.currency_digital_code !==
                                                                                    643
                                                                                        ? Math.round(
                                                                                              value *
                                                                                                  (prev.exchange_rate ||
                                                                                                      1) *
                                                                                                  100
                                                                                          ) /
                                                                                          100
                                                                                        : undefined

                                                                                return {
                                                                                    ...dt,
                                                                                    summ: value,
                                                                                    ...(summRub !==
                                                                                        undefined && {
                                                                                        summ_rub:
                                                                                            summRub,
                                                                                    }),
                                                                                }
                                                                            }

                                                                            return dt
                                                                        }
                                                                    )

                                                                // Округляем volumeRub для точности отображения итоговой суммы в рублях
                                                                const volumeRub =
                                                                    prev.currency_digital_code !==
                                                                    643
                                                                        ? Math.round(
                                                                              newVolume *
                                                                                  (prev.exchange_rate ||
                                                                                      1) *
                                                                                  100
                                                                          ) /
                                                                          100
                                                                        : undefined

                                                                return {
                                                                    ...prev,
                                                                    long_term_volumes:
                                                                        {
                                                                            ...prev.long_term_volumes,
                                                                            volume: newVolume,
                                                                            volume_rub:
                                                                                volumeRub,
                                                                            details:
                                                                                {
                                                                                    items: updatedItems,
                                                                                },
                                                                        },
                                                                }
                                                            })
                                                        }
                                                    }
                                                }}
                                                currency={
                                                    pos.currency_digital_code
                                                }
                                                currencyExchangeDate={
                                                    pos.exchange_rate_date
                                                        ? new Date(
                                                              pos.exchange_rate_date
                                                          )
                                                        : undefined
                                                }
                                                currencyExchangeRate={
                                                    pos.exchange_rate ??
                                                    undefined
                                                }
                                            />
                                        </div>
                                        <div className="basis-1/2">
                                            <PurchasePaymentBreakdown
                                                title="Информация об объемах привлечения субъектов МСП"
                                                yearlyPayments={
                                                    pos.long_term_smb_volumes
                                                }
                                                onYearlyPaymentsChange={(
                                                    year,
                                                    value
                                                ) => {
                                                    if (
                                                        pos.long_term_smb_volumes
                                                    ) {
                                                        const item =
                                                            pos.long_term_smb_volumes.details.items.find(
                                                                (y) =>
                                                                    y.year ===
                                                                    year
                                                            )
                                                        if (item) {
                                                            setPos((prev) => {
                                                                if (
                                                                    prev.long_term_smb_volumes ===
                                                                    null
                                                                ) {
                                                                    return prev
                                                                }

                                                                // Округляем newVolume, чтобы избежать ошибок с плавающей точкой
                                                                const newVolume =
                                                                    Math.round(
                                                                        (prev
                                                                            .long_term_smb_volumes
                                                                            .volume -
                                                                            item.summ +
                                                                            value) *
                                                                            100
                                                                    ) / 100

                                                                const updatedItems =
                                                                    prev.long_term_smb_volumes.details.items.map(
                                                                        (
                                                                            dt
                                                                        ) => {
                                                                            if (
                                                                                dt.year ===
                                                                                year
                                                                            ) {
                                                                                // Округляем summRub для предотвращения ошибок при пересчёте суммы в рубли
                                                                                const summRub =
                                                                                    prev.currency_digital_code !==
                                                                                    643
                                                                                        ? Math.round(
                                                                                              value *
                                                                                                  (prev.exchange_rate ||
                                                                                                      1) *
                                                                                                  100
                                                                                          ) /
                                                                                          100
                                                                                        : undefined

                                                                                return {
                                                                                    ...dt,
                                                                                    summ: value,
                                                                                    ...(summRub !==
                                                                                        undefined && {
                                                                                        summ_rub:
                                                                                            summRub,
                                                                                    }),
                                                                                }
                                                                            }

                                                                            return dt
                                                                        }
                                                                    )

                                                                // Округляем volumeRub для точности отображения итоговой суммы в рублях
                                                                const volumeRub =
                                                                    prev.currency_digital_code !==
                                                                    643
                                                                        ? Math.round(
                                                                              newVolume *
                                                                                  (prev.exchange_rate ||
                                                                                      1) *
                                                                                  100
                                                                          ) /
                                                                          100
                                                                        : undefined

                                                                return {
                                                                    ...prev,
                                                                    long_term_smb_volumes:
                                                                        {
                                                                            ...prev.long_term_smb_volumes,
                                                                            volume: newVolume,
                                                                            volume_rub:
                                                                                volumeRub,
                                                                            details:
                                                                                {
                                                                                    items: updatedItems,
                                                                                },
                                                                        },
                                                                }
                                                            })
                                                        }
                                                    }
                                                }}
                                                currency={
                                                    pos.currency_digital_code
                                                }
                                                currencyExchangeDate={
                                                    pos.exchange_rate_date
                                                        ? new Date(
                                                              pos.exchange_rate_date
                                                          )
                                                        : undefined
                                                }
                                                currencyExchangeRate={
                                                    pos.exchange_rate ??
                                                    undefined
                                                }
                                            />
                                        </div>
                                    </div>
                                )}

                            {isUserRoleName == 'admin' ||
                            isUserRoleName == 'specialist' ? (
                                <PurchaseMethod
                                    posStatus={pos.status}
                                    methodCode={pos.purchase_method_code}
                                    onMethodCodeChange={(code) => {
                                        setPos((prev) => ({
                                            ...prev,
                                            purchase_method_code: code,
                                        }))
                                    }}
                                    method={pos.purchase_method_name}
                                    onMethodChange={(name) => {
                                        setPos((prev) => ({
                                            ...prev,
                                            purchase_method_name: name,
                                        }))
                                    }}
                                    isElectronic={pos.is_electronic}
                                    onIsElectronicChange={(electronic) => {
                                        setPos((prev) => ({
                                            ...prev,
                                            is_electronic: electronic,
                                        }))
                                    }}
                                    basisSingleSupplier={
                                        pos.single_purchase_reason_name
                                    }
                                    onBasisSingleSupplierChange={(name) => {
                                        setPos((prev) => ({
                                            ...prev,
                                            single_purchase_reason_name: name,
                                        }))
                                    }}
                                    areParticipantsSME={pos.is_purchase_smb}
                                    onAreParticipantsSMEChange={(
                                        isPurchaseSmb
                                    ) => {
                                        setPos((prev) => ({
                                            ...prev,
                                            is_purchase_smb: isPurchaseSmb,
                                        }))
                                    }}
                                    isPlannedThirdYear={
                                        pos.planned_after_second_year
                                    }
                                    onIsPlannedThirdYearChange={(
                                        plannedAfterSecondYear
                                    ) => {
                                        setPos((prev) => ({
                                            ...prev,
                                            planned_after_second_year:
                                                plannedAfterSecondYear,
                                        }))
                                    }}
                                    isInnovativeProduct={
                                        pos.innovation_equivalent
                                    }
                                    onIsInnovativeProductChange={(
                                        innovationEquivalent
                                    ) => {
                                        setPos((prev) => ({
                                            ...prev,
                                            innovation_equivalent:
                                                innovationEquivalent,
                                        }))
                                    }}
                                    isExcludedForSME={pos.is_purchase_ignored}
                                    onIsExcludedForSMEChange={(
                                        isPurchaseIgnored
                                    ) => {
                                        setPos((prev) => ({
                                            ...prev,
                                            is_purchase_ignored:
                                                isPurchaseIgnored,
                                        }))
                                    }}
                                    category={pos.purchase_category}
                                    onCategoryChange={(category) => {
                                        setPos((prev) => ({
                                            ...prev,
                                            purchase_category: category,
                                        }))
                                    }}
                                    posMSPStatus={
                                        pos.is_purchase_smb
                                            ? pos.is_purchase_smb
                                            : false
                                    }
                                />
                            ) : (
                                ''
                            )}

                            <PurchaseItemsSection
                                planGuid={pos.plan_guid}
                                posGuid={pos.guid}
                                posMSPStatus={pos.is_purchase_smb}
                                purchaseMethodCode={pos.purchase_method_code}
                                purchaseMethodName={pos.purchase_method_name}
                                isDraft={pos.position_status !== 'Размещена'} // TODO: Iosif to check this (pos.position_status === 'Черновик')
                                isGeneralAddress={pos.is_general_address}
                                onIsGeneralAddressChange={(generalAddress) => {
                                    setPos((prev) => ({
                                        ...prev,
                                        is_general_address: generalAddress,
                                    }))
                                }}
                                okatoCode={pos.okato}
                                onOkatoCodeChange={(okato) => {
                                    setPos((prev) => ({
                                        ...prev,
                                        okato: okato,
                                    }))
                                }}
                                region={pos.region}
                                onRegionChange={(name) => {
                                    setPos((prev) => ({
                                        ...prev,
                                        region: name,
                                    }))
                                }}
                                onAreParticipantsSMEChange={(isPurchaseSmb) => {
                                    setPos((prev) => ({
                                        ...prev,
                                        is_purchase_smb: isPurchaseSmb,
                                    }))
                                }}
                            />
                        </main>
                    </fieldset>
                </div>

                {/* блок согласования */}
                {/* <ApprovalsList
                    pos={pos}
                    user={user?.object.user_id}
                    structList={structList}
                    userList={userList}
                />

                <br /> */}

                <div className="flex flex-col min-w-[590px] h-fit rounded-lg gap-5 items-center justify-start bg-[#262427] px-5 py-5">
                    <div className="flex flex-col w-full">
                        <div className="flex flex-col w-fit py-2 px-5 border-b-2 border-[#494849] text-white text-[14px]">
                            Согласование
                        </div>
                    </div>
                    <div className="flex flex-row w-full p-3 rounded-md bg-[#333033] items-center justify-start">
                        <button
                            className={`flex flex-row gap-3 px-5 py-1 rounded-[10px]  text-[12.8px]  \
                                ${
                                    pos.user_guid === user?.object.user_id &&
                                    !approvals.some(
                                        (approval) => approval.status === 0
                                    )
                                        ? 'bg-[#494849] hover:bg-[#656365] text-white'
                                        : 'bg-[#494849] text-[#8A8A8A]'
                                }`}
                            onClick={() => {
                                setApproversAdd([])
                                setApprovalChangeGuid('')
                                setApprovalAddGuid('')
                                handleApprovalPopup()
                            }}
                            disabled={
                                pos.user_guid === user?.object.user_id &&
                                !approvals.some(
                                    (approval) => approval.status === 0
                                )
                                    ? false
                                    : true
                            }
                        >
                            Создать согласование
                        </button>
                    </div>

                    {approvals.map((approval, index) => (
                        <div
                            key={index}
                            className="flex flex-col p-3 gap-1 rounded-md bg-[#2C2A2E] w-full"
                        >
                            <div className="flex flex-row w-full gap-3 items-center justify-start">
                                <div
                                    className={`flex flex-row w-4 h-4  rounded-full 
                                        ${
                                            approval.status == 0
                                                ? 'bg-[#FAB502]'
                                                : approval.status == 1 ||
                                                    approval.status == 2
                                                  ? 'bg-[#049664]'
                                                  : approval.status == 3
                                                    ? 'bg-[#C15C5C]'
                                                    : approval.status == 4
                                                      ? 'bg-[#464447]'
                                                      : ''
                                        }`}
                                ></div>
                                <div className="flex flex-row py-1 text-white text-[14px]">
                                    {approval.status == 0
                                        ? 'Идет согласование'
                                        : approval.status == 1 ||
                                            approval.status == 2
                                          ? 'Согласовано'
                                          : approval.status == 3
                                            ? 'Не согласовано'
                                            : approval.status == 4
                                              ? 'Отменена'
                                              : ''}
                                </div>
                                <div className="flex flex-1 w-full items-center justify-end">
                                    <div
                                        className="flex flex-col w-6 h-6 rounded-full cursor-pointer bg-[#494849] hover:bg-[#656365] items-center justify-center text-white"
                                        onClick={() =>
                                            toggleAccordion(
                                                index,
                                                approval.guid
                                            )
                                        }
                                        title={`${expandedIndex === index ? 'Скрыть' : 'Показать'}`}
                                    >
                                        {expandedIndex === index ? (
                                            <svg
                                                className="w-3 h-3"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                                id="down"
                                            >
                                                <path
                                                    fill={'#FFFFFF'}
                                                    d="M17,13.41,12.71,9.17a1,1,0,0,0-1.42,0L7.05,13.41a1,1,0,0,0,0,1.42,1,1,0,0,0,1.41,0L12,11.29l3.54,3.54a1,1,0,0,0,.7.29,1,1,0,0,0,.71-.29A1,1,0,0,0,17,13.41Z"
                                                ></path>
                                            </svg>
                                        ) : (
                                            <svg
                                                className="w-3 h-3"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                                id="down"
                                            >
                                                <path
                                                    fill={'#FFFFFF'}
                                                    d="M17,9.17a1,1,0,0,0-1.41,0L12,12.71,8.46,9.17a1,1,0,0,0-1.41,0,1,1,0,0,0,0,1.42l4.24,4.24a1,1,0,0,0,1.42,0L17,10.59A1,1,0,0,0,17,9.17Z"
                                                ></path>
                                            </svg>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-row w-full gap-3 items-center justify-start">
                                <div className="flex flex-col w-full gap-1 items-start justify-start">
                                    <div className="flex flex-col w-full items-start justify-start text-[#8A8A8A] text-[12.8px]">
                                        Начало согласования
                                    </div>
                                    <div className="flex w-full items-start justify-start">
                                        <input
                                            type="date"
                                            className="form-input w-full focus:outline-none focus:ring-1 focus:ring-[#FFFFFF] bg-[#1F1E1F]"
                                            value={approval.start_date}
                                            disabled
                                        ></input>
                                    </div>
                                </div>
                                <div className="flex flex-col w-full gap-1 items-start justify-start">
                                    <div className="flex flex-col w-full items-start justify-start text-[#8A8A8A] text-[12.8px]">
                                        Срок окончания
                                    </div>
                                    <div className="flex w-full items-start justify-start">
                                        <input
                                            type="date"
                                            className="form-input w-full focus:outline-none focus:ring-1 focus:ring-[#FFFFFF] bg-[#1F1E1F]"
                                            value={approval.end_date}
                                            disabled
                                        ></input>
                                    </div>
                                </div>
                                <div className="flex flex-col w-full gap-1 items-start justify-start">
                                    <div className="flex flex-col w-full items-start justify-start text-[#8A8A8A] text-[12.8px]">
                                        Приоритет
                                    </div>
                                    <div className="flex w-full items-start justify-start">
                                        <select
                                            className={`flex px-2 py-1 rounded-md border-0 text-sm w-full focus:outline-none focus:ring-1 focus:ring-[#FFFFFF] 
                                                ${
                                                    approval.priority == 0
                                                        ? 'bg-[#1F1E1F] text-white'
                                                        : 'bg-[#C15C5C] text-[#611212]'
                                                } `}
                                            value={approval.priority}
                                            disabled
                                        >
                                            <option value={''}>Выбрать</option>
                                            <option value={0}>
                                                Отсутствует
                                            </option>
                                            <option value={1}>Важно</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            {approval.comment && (
                                <div className="flex flex-col w-full gap-1 items-start justify-center">
                                    <div className="flex w-full items-start justify-start text-[#8A8A8A] text-[12.8px]">
                                        Комментарий
                                    </div>
                                    <div className="flex w-full items-start justify-start">
                                        <textarea
                                            className="form-textarea flex w-full"
                                            maxLength={2000}
                                            rows={2}
                                            value={approval.comment}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                            )}
                            {expandedIndex === index &&
                                (loading ? (
                                    <div className="flex flex-col w-full text-white items-center justify-center">
                                        <svg
                                            className="animate-spin h-4 w-4 text-white"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                        >
                                            <circle
                                                className="opacity-25"
                                                cx="12"
                                                cy="12"
                                                r="10"
                                                stroke="currentColor"
                                                strokeWidth="4"
                                            ></circle>
                                            <path
                                                className="opacity-75"
                                                fill="currentColor"
                                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                            ></path>
                                        </svg>
                                    </div>
                                ) : approvers?.length > 0 ? (
                                    <>
                                        <div className="flex flex-row px-2 py-1 pt-3 text-white text-[14px]">
                                            Лист согласования
                                        </div>
                                        <div className="flex flex-col w-full items-center justify-center">
                                            <div className="flex flex-row w-full gap-1 p-1 py-3 items-center justify-center rounded-t-md text-[#8A8A8A] text-[12.8px] bg-[#333033]">
                                                <div className="flex w-10 items-center justify-center ">
                                                    №
                                                </div>
                                                <div className="flex flex-1 items-center justify-center ">
                                                    Согласующий
                                                </div>
                                                <div className="flex w-32 items-center justify-center ">
                                                    Дата
                                                </div>
                                                <div className="flex w-40 items-center justify-center ">
                                                    Виза
                                                </div>
                                            </div>
                                            <div className="flex flex-col w-full gap-1 p-1 items-center justify-center rounded-b-md bg-[#242225]">
                                                {approvers.map((approver) => (
                                                    <div
                                                        key={approver.guid}
                                                        className="flex flex-col w-full gap-1 p-1 items-center justify-center rounded-md bg-[#2A282B]"
                                                    >
                                                        <div className="flex flex-row w-full gap-1 items-center justify-center text-[14px] ">
                                                            <div className="flex w-10 items-center justify-center text-[#8A8A8A]">
                                                                {
                                                                    approver.user_position
                                                                }
                                                            </div>
                                                            <div className="flex flex-1 flex-row gap-2 items-center justify-start text-white ">
                                                                {approver.is_saw ? (
                                                                    <div
                                                                        className="flex w-5 h-5 rounded-full items-center justify-center"
                                                                        title="Просмотрено"
                                                                    >
                                                                        <svg
                                                                            className="w-3 h-3"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 24 24"
                                                                            id="trash-alt"
                                                                        >
                                                                            <path
                                                                                fill="#FFFFFF"
                                                                                d="M21.92,11.6C19.9,6.91,16.1,4,12,4S4.1,6.91,2.08,11.6a1,1,0,0,0,0,.8C4.1,17.09,7.9,20,12,20s7.9-2.91,9.92-7.6A1,1,0,0,0,21.92,11.6ZM12,18c-3.17,0-6.17-2.29-7.9-6C5.83,8.29,8.83,6,12,6s6.17,2.29,7.9,6C18.17,15.71,15.17,18,12,18ZM12,8a4,4,0,1,0,4,4A4,4,0,0,0,12,8Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,12,14Z"
                                                                            ></path>
                                                                        </svg>
                                                                    </div>
                                                                ) : (
                                                                    <div
                                                                        className="flex w-5 h-5 rounded-full items-center justify-center"
                                                                        title="Не просмотрено"
                                                                    >
                                                                        <svg
                                                                            className="w-3 h-3"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 24 24"
                                                                            id="trash-alt"
                                                                        >
                                                                            <path
                                                                                fill="#FFFFFF"
                                                                                d="M10.94,6.08A6.93,6.93,0,0,1,12,6c3.18,0,6.17,2.29,7.91,6a15.23,15.23,0,0,1-.9,1.64,1,1,0,0,0-.16.55,1,1,0,0,0,1.86.5,15.77,15.77,0,0,0,1.21-2.3,1,1,0,0,0,0-.79C19.9,6.91,16.1,4,12,4a7.77,7.77,0,0,0-1.4.12,1,1,0,1,0,.34,2ZM3.71,2.29A1,1,0,0,0,2.29,3.71L5.39,6.8a14.62,14.62,0,0,0-3.31,4.8,1,1,0,0,0,0,.8C4.1,17.09,7.9,20,12,20a9.26,9.26,0,0,0,5.05-1.54l3.24,3.25a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Zm6.36,9.19,2.45,2.45A1.81,1.81,0,0,1,12,14a2,2,0,0,1-2-2A1.81,1.81,0,0,1,10.07,11.48ZM12,18c-3.18,0-6.17-2.29-7.9-6A12.09,12.09,0,0,1,6.8,8.21L8.57,10A4,4,0,0,0,14,15.43L15.59,17A7.24,7.24,0,0,1,12,18Z"
                                                                            ></path>
                                                                        </svg>
                                                                    </div>
                                                                )}
                                                                {`${approver.last_name ? approver.last_name : ''} ${approver.first_name ? approver.first_name.charAt(0) + '.' : ''} ${approver.patronymic ? approver.patronymic.charAt(0) + '.' : ''}`}
                                                            </div>
                                                            <div className="flex w-40 items-center justify-center text-white ">
                                                                {approver.approve_date
                                                                    ? approver.approve_date
                                                                          .split(
                                                                              '-'
                                                                          )
                                                                          .reverse()
                                                                          .join(
                                                                              '-'
                                                                          )
                                                                          .replace(
                                                                              /[\-]/g,
                                                                              '.'
                                                                          )
                                                                    : '-'}
                                                            </div>
                                                            <div className="flex w-40 items-center justify-center text-white ">
                                                                <input
                                                                    className={`flex px-2 py-1 rounded-md text-sm w-full items-center justify-center text-center focus:outline-none focus:ring-1 focus:ring-[#FFFFFF] 
                                                                            ${
                                                                                approver.status ==
                                                                                0
                                                                                    ? 'bg-[#FAD12E] text-[#3A2506]'
                                                                                    : approver.status ==
                                                                                        1
                                                                                      ? 'bg-[#049664] text-[#103917]'
                                                                                      : approver.status ==
                                                                                          2
                                                                                        ? 'bg-[#049664] text-[#103917]'
                                                                                        : approver.status ==
                                                                                            3
                                                                                          ? 'bg-[#C15C5C] text-[#611212]'
                                                                                          : ''
                                                                            }`}
                                                                    value={
                                                                        approver.status ==
                                                                        0
                                                                            ? 'Согласование'
                                                                            : approver.status ==
                                                                                1
                                                                              ? 'Согласовано'
                                                                              : approver.status ==
                                                                                  2
                                                                                ? 'Согласовано с замечаниями'
                                                                                : approver.status ==
                                                                                    3
                                                                                  ? 'Не согласовано'
                                                                                  : ''
                                                                    }
                                                                    disabled
                                                                    title={
                                                                        approver.status ==
                                                                        0
                                                                            ? 'Согласование'
                                                                            : approver.status ==
                                                                                1
                                                                              ? 'Согласовано'
                                                                              : approver.status ==
                                                                                  2
                                                                                ? 'Согласовано с замечаниями'
                                                                                : approver.status ==
                                                                                    3
                                                                                  ? 'Не согласовано'
                                                                                  : ''
                                                                    }
                                                                ></input>
                                                            </div>
                                                        </div>

                                                        {approver.comment && (
                                                            <div className="flex flex-col w-full gap-1 items-start justify-center">
                                                                <div className="flex w-full items-start justify-start text-[#8A8A8A] text-[12.8px]">
                                                                    Комментарий
                                                                </div>
                                                                <div className="flex w-full items-start justify-start">
                                                                    <textarea
                                                                        className="form-textarea flex w-full"
                                                                        maxLength={
                                                                            2000
                                                                        }
                                                                        rows={2}
                                                                        value={
                                                                            approver.comment
                                                                        }
                                                                        disabled={
                                                                            true
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                        {approval.status == 0
                                            ? userApprovers.map(
                                                  (userApprove, index2) =>
                                                      userApprove.status ==
                                                      0 ? (
                                                          <>
                                                              <div className="flex flex-row px-2 py-1 pt-3 text-white text-[14px]">
                                                                  Резолюция
                                                              </div>
                                                              <div
                                                                  key={index2}
                                                                  className="flex flex-col w-full gap-3 p-2 items-start justify-start bg-[#262427] rounded-md"
                                                              >
                                                                  <div className="flex flex-row w-full gap-1 items-start justify-start text-[14px]">
                                                                      <div className="flex flex-col flex-1 w-full items-start justify-start text-white ">
                                                                          <div className="flex w-full items-start justify-start text-[#8A8A8A] text-[12.8px]">
                                                                              Согласующий
                                                                          </div>
                                                                          <div className="flex w-full items-start justify-start text-white text-[14px]">
                                                                              {`${userApprove.last_name} ${userApprove.first_name} ${userApprove.patronymic}`}
                                                                          </div>
                                                                      </div>
                                                                      <div className="flex flex-col w-[165px] items-start justify-center text-white ">
                                                                          <div className="flex w-full items-start justify-start text-[#8A8A8A] text-[12.8px]">
                                                                              Виза
                                                                          </div>
                                                                          <div className="flex w-full items-start justify-start">
                                                                              <select
                                                                                  className={`form-select w-full focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]`}
                                                                                  value={
                                                                                      selectedStatus ||
                                                                                      0
                                                                                  }
                                                                                  onChange={(
                                                                                      e
                                                                                  ) =>
                                                                                      setSelectedStatus(
                                                                                          Number(
                                                                                              e
                                                                                                  .target
                                                                                                  .value
                                                                                          )
                                                                                      )
                                                                                  }
                                                                              >
                                                                                  <option
                                                                                      value={
                                                                                          0
                                                                                      }
                                                                                  >
                                                                                      Выбрать
                                                                                  </option>
                                                                                  <option
                                                                                      value={
                                                                                          1
                                                                                      }
                                                                                  >
                                                                                      Согласовано
                                                                                  </option>
                                                                                  <option
                                                                                      value={
                                                                                          2
                                                                                      }
                                                                                  >
                                                                                      Согласовано
                                                                                      с
                                                                                      замечаниями
                                                                                  </option>
                                                                                  <option
                                                                                      value={
                                                                                          3
                                                                                      }
                                                                                  >
                                                                                      Не
                                                                                      согласовано
                                                                                  </option>
                                                                              </select>
                                                                          </div>
                                                                      </div>
                                                                  </div>
                                                                  <div className="flex flex-col w-full gap-1 items-start justify-start">
                                                                      <div className="flex w-full items-start justify-start text-[#8A8A8A] text-[12.8px]">
                                                                          Комментарий
                                                                      </div>
                                                                      <div className="flex w-full items-start justify-start">
                                                                          <textarea
                                                                              className="form-textarea flex w-full"
                                                                              maxLength={
                                                                                  2000
                                                                              }
                                                                              rows={
                                                                                  2
                                                                              }
                                                                              value={
                                                                                  comment
                                                                              }
                                                                              onChange={(
                                                                                  e
                                                                              ) => {
                                                                                  setComment(
                                                                                      e
                                                                                          .target
                                                                                          .value
                                                                                  )
                                                                              }}
                                                                          />
                                                                      </div>
                                                                  </div>

                                                                  <div className="flex flex-row w-full gap-1 items-center justify-end">
                                                                      <button
                                                                          className={`flex gap-3 px-5 py-1 h-6 min-w-[120px] rounded-[10px] text-[12.8px] items-center justify-center ${!selectedStatus || saveLoading ? 'bg-[#494849] text-[#8A8A8A]' : 'text-white bg-[#187A33] hover:bg-[#1D9A40]'}`}
                                                                          disabled={
                                                                              !selectedStatus ||
                                                                              saveLoading
                                                                          }
                                                                          onClick={() =>
                                                                              saveApprovalResult(
                                                                                  userApprove.guid,
                                                                                  selectedStatus!,
                                                                                  comment
                                                                              )
                                                                          }
                                                                      >
                                                                          Сохранить
                                                                      </button>
                                                                  </div>
                                                              </div>
                                                          </>
                                                      ) : (
                                                          ''
                                                      )
                                              )
                                            : ''}
                                        <div className="flex flex-row w-full items-center justify-end">
                                            <div className="flex flex-row w-full items-center justify-start gap-2 pt-2">
                                                {pos.user_guid ==
                                                    user?.object.user_id &&
                                                approval.status == 0 ? (
                                                    <button
                                                        onClick={() =>
                                                            handleApprovalDelete(
                                                                approval.guid
                                                            )
                                                        }
                                                        className="flex flex-row px-2 min-w-[120px] h-6 rounded-[10px] items-center justify-center bg-[#494849] hover:bg-[#701F1F] gap-1"
                                                        title="Удалить"
                                                    >
                                                        <svg
                                                            className="w-3 h-3"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 24 24"
                                                            id="trash-alt"
                                                        >
                                                            <path
                                                                fill="#FFFFFF"
                                                                d="M10,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,10,18ZM20,6H16V5a3,3,0,0,0-3-3H11A3,3,0,0,0,8,5V6H4A1,1,0,0,0,4,8H5V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V8h1a1,1,0,0,0,0-2ZM10,5a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1V6H10Zm7,14a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V8H17Zm-3-1a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,14,18Z"
                                                            ></path>
                                                        </svg>
                                                        <span className="text-white text-[12.8px]">
                                                            Удалить
                                                        </span>
                                                    </button>
                                                ) : (
                                                    ''
                                                )}
                                            </div>

                                            {pos.user_guid !=
                                                user?.object.user_id &&
                                            approval.status == 0 &&
                                            approvers.some(
                                                (approver) =>
                                                    approver.user_guid ===
                                                    user?.object.user_id
                                            ) ? (
                                                <div className="flex flex-row w-full items-center justify-end gap-2 pt-2">
                                                    <button
                                                        onClick={() => {
                                                            const addGuid =
                                                                String(
                                                                    approval.guid
                                                                ) // Локальная переменная
                                                            setApprovalAddGuid(
                                                                addGuid
                                                            )
                                                            setApprovalChangeGuid(
                                                                ''
                                                            )
                                                            setApproversAdd([])
                                                            handleApprovalPopup()
                                                        }}
                                                        className="flex flex-row px-2 min-w-[120px] h-6 rounded-[10px] items-center justify-center bg-[#494849] hover:bg-[#656365] gap-1"
                                                        title="Добавить согласующего"
                                                    >
                                                        <svg
                                                            className="w-3 h-3"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 24 24"
                                                            id="add-approver"
                                                        >
                                                            <path
                                                                fill="#FFFFFF"
                                                                d="M19,11H13V5a1,1,0,0,0-2,0v6H5a1,1,0,0,0,0,2h6v6a1,1,0,0,0,2,0V13h6a1,1,0,0,0,0-2Z"
                                                            ></path>
                                                        </svg>
                                                        <span className="text-white text-[12.8px]">
                                                            Добавить
                                                            согласующего
                                                        </span>
                                                    </button>
                                                </div>
                                            ) : (
                                                ''
                                            )}

                                            {pos.user_guid ==
                                                user?.object.user_id &&
                                            approval.status == 0 ? (
                                                <div className="flex flex-row w-full items-center justify-end gap-2 pt-2">
                                                    <button
                                                        onClick={() => {
                                                            const changeGuid =
                                                                String(
                                                                    approval.guid
                                                                ) // Локальная переменная
                                                            setApprovalChangeGuid(
                                                                changeGuid
                                                            )
                                                            setApprovalAddGuid(
                                                                ''
                                                            )
                                                            setApproversAdd([])
                                                            handleApprovalPopup()
                                                        }}
                                                        className="flex flex-row px-2 min-w-[120px] h-6 rounded-[10px] items-center justify-center bg-[#494849] hover:bg-[#656365] gap-1"
                                                        title="Редактировать"
                                                    >
                                                        <svg
                                                            className="w-3 h-3"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 24 24"
                                                            id="change-approval"
                                                        >
                                                            <path
                                                                fill="#FFFFFF"
                                                                d="M5,18H9.24a1,1,0,0,0,.71-.29l6.92-6.93h0L19.71,8a1,1,0,0,0,0-1.42L15.47,2.29a1,1,0,0,0-1.42,0L11.23,5.12h0L4.29,12.05a1,1,0,0,0-.29.71V17A1,1,0,0,0,5,18ZM14.76,4.41l2.83,2.83L16.17,8.66,13.34,5.83ZM6,13.17l5.93-5.93,2.83,2.83L8.83,16H6ZM21,20H3a1,1,0,0,0,0,2H21a1,1,0,0,0,0-2Z"
                                                            ></path>
                                                        </svg>
                                                        <span className="text-white text-[12.8px]">
                                                            Редактировать
                                                        </span>
                                                    </button>
                                                </div>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="text-[#8A8A8A] text-center text-[12.8px]">
                                            Согласующие отсутствуют
                                        </div>
                                        <div className="flex flex-row w-full items-center justify-end">
                                            <div className="flex flex-row w-full items-center justify-start gap-2 pt-2">
                                                {pos.user_guid ==
                                                    user?.object.user_id &&
                                                approval.status == 0 ? (
                                                    <button
                                                        onClick={() =>
                                                            handleApprovalDelete(
                                                                approval.guid
                                                            )
                                                        }
                                                        className="flex flex-row px-2 min-w-[120px] h-6 rounded-[10px] items-center justify-center bg-[#494849] hover:bg-[#701F1F] gap-1"
                                                        title="Удалить"
                                                    >
                                                        <svg
                                                            className="w-3 h-3"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 24 24"
                                                            id="trash-alt"
                                                        >
                                                            <path
                                                                fill="#FFFFFF"
                                                                d="M10,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,10,18ZM20,6H16V5a3,3,0,0,0-3-3H11A3,3,0,0,0,8,5V6H4A1,1,0,0,0,4,8H5V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V8h1a1,1,0,0,0,0-2ZM10,5a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1V6H10Zm7,14a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V8H17Zm-3-1a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,14,18Z"
                                                            ></path>
                                                        </svg>
                                                        <span className="text-white text-[12.8px]">
                                                            Удалить
                                                        </span>
                                                    </button>
                                                ) : (
                                                    ''
                                                )}
                                            </div>

                                            {pos.user_guid !=
                                                user?.object.user_id &&
                                            approval.status == 0 &&
                                            approvers.some(
                                                (approver) =>
                                                    approver.user_guid ===
                                                    user?.object.user_id
                                            ) ? (
                                                <div className="flex flex-row w-full items-center justify-end gap-2 pt-2">
                                                    <button
                                                        onClick={() => {
                                                            const addGuid =
                                                                String(
                                                                    approval.guid
                                                                ) // Локальная переменная
                                                            setApprovalAddGuid(
                                                                addGuid
                                                            )
                                                            setApprovalChangeGuid(
                                                                ''
                                                            )
                                                            setApproversAdd([])
                                                            handleApprovalPopup()
                                                        }}
                                                        className="flex flex-row px-2 min-w-[120px] h-6 rounded-[10px] items-center justify-center bg-[#494849] hover:bg-[#656365] gap-1"
                                                        title="Добавить согласующего"
                                                    >
                                                        <svg
                                                            className="w-3 h-3"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 24 24"
                                                            id="add-approver"
                                                        >
                                                            <path
                                                                fill="#FFFFFF"
                                                                d="M19,11H13V5a1,1,0,0,0-2,0v6H5a1,1,0,0,0,0,2h6v6a1,1,0,0,0,2,0V13h6a1,1,0,0,0,0-2Z"
                                                            ></path>
                                                        </svg>
                                                        <span className="text-white text-[12.8px]">
                                                            Добавить
                                                            согласующего
                                                        </span>
                                                    </button>
                                                </div>
                                            ) : (
                                                ''
                                            )}

                                            {pos.user_guid ==
                                                user?.object.user_id &&
                                            approval.status == 0 ? (
                                                <div className="flex flex-row w-full items-center justify-end gap-2 pt-2">
                                                    <button
                                                        onClick={() => {
                                                            const changeGuid =
                                                                String(
                                                                    approval.guid
                                                                ) // Локальная переменная
                                                            setApprovalChangeGuid(
                                                                changeGuid
                                                            )
                                                            setApprovalAddGuid(
                                                                ''
                                                            )
                                                            setApproversAdd([])
                                                            handleApprovalPopup()
                                                        }}
                                                        className="flex flex-row px-2 min-w-[120px] h-6 rounded-[10px] items-center justify-center bg-[#494849] hover:bg-[#656365] gap-1"
                                                        title="Редактировать"
                                                    >
                                                        <svg
                                                            className="w-3 h-3"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 24 24"
                                                            id="change-approval"
                                                        >
                                                            <path
                                                                fill="#FFFFFF"
                                                                d="M5,18H9.24a1,1,0,0,0,.71-.29l6.92-6.93h0L19.71,8a1,1,0,0,0,0-1.42L15.47,2.29a1,1,0,0,0-1.42,0L11.23,5.12h0L4.29,12.05a1,1,0,0,0-.29.71V17A1,1,0,0,0,5,18ZM14.76,4.41l2.83,2.83L16.17,8.66,13.34,5.83ZM6,13.17l5.93-5.93,2.83,2.83L8.83,16H6ZM21,20H3a1,1,0,0,0,0,2H21a1,1,0,0,0,0-2Z"
                                                            ></path>
                                                        </svg>
                                                        <span className="text-white text-[12.8px]">
                                                            Редактировать
                                                        </span>
                                                    </button>
                                                </div>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                    </>
                                ))}
                        </div>
                    ))}
                </div>
            </div>

            {approvalCreatePopup && (
                <div
                    className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center"
                    onClick={() => {
                        setApproversAdd([])
                        setApprovalChangeGuid('')
                        setApprovalAddGuid('')
                        handleApprovalPopup()
                    }}
                >
                    <div
                        className="flex flex-col p-5 rounded-lg bg-[#262427] w-[700px] h-fit gap-y-5"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <h2 className="text-white">
                            {approvalChangeGuid
                                ? 'Изменение согласования'
                                : approvalAddGuid
                                  ? 'Добавление согласующего'
                                  : 'Создание согласования'}
                        </h2>
                        <div className="flex flex-row w-full gap-3 items-center justify-start">
                            <div className="flex flex-col w-full gap-1 items-start justify-start">
                                <div className="flex flex-col w-full items-start justify-start text-[#8A8A8A] text-[12.8px]">
                                    Начало согласования
                                </div>
                                <div className="flex w-full items-start justify-start">
                                    <input
                                        type="date"
                                        className="form-input w-full focus:outline-none focus:ring-1 focus:ring-[#FFFFFF] bg-[#1F1E1F]"
                                        value={approvalStartDate}
                                        onChange={(e) =>
                                            setApprovalStartDate(e.target.value)
                                        }
                                        disabled={
                                            approvalAddGuid ? true : false
                                        }
                                    ></input>
                                </div>
                            </div>
                            <div className="flex flex-col w-full gap-1 items-start justify-start">
                                <div className="flex flex-col w-full items-start justify-start text-[#8A8A8A] text-[12.8px]">
                                    Срок окончания
                                </div>
                                <div className="flex w-full items-start justify-start">
                                    <input
                                        type="date"
                                        className="form-input w-full focus:outline-none focus:ring-1 focus:ring-[#FFFFFF] bg-[#1F1E1F]"
                                        value={approvalEndDate}
                                        onChange={(e) =>
                                            setApprovalEndDate(e.target.value)
                                        }
                                        disabled={
                                            approvalAddGuid ? true : false
                                        }
                                    ></input>
                                </div>
                            </div>
                            <div className="flex flex-col w-full gap-1 items-start justify-start">
                                <div className="flex flex-col w-full items-start justify-start text-[#8A8A8A] text-[12.8px]">
                                    Приоритет
                                </div>
                                <div className="flex w-full items-start justify-start">
                                    <select
                                        className={`form-select w-full focus:outline-none focus:ring-1 focus:ring-[#FFFFFF] `}
                                        value={approvalPriority}
                                        onChange={(e) =>
                                            setApprovalPriority(
                                                Number(e.target.value)
                                            )
                                        }
                                        disabled={
                                            approvalAddGuid ? true : false
                                        }
                                    >
                                        <option value={-1}>Выбрать</option>
                                        <option value={0}>Отсутствует</option>
                                        <option value={1}>Важно</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col w-full gap-1 items-start justify-center">
                            <div className="flex w-full items-start justify-start text-[#8A8A8A] text-[12.8px]">
                                Комментарий
                            </div>
                            <div className="flex w-full items-start justify-start">
                                <textarea
                                    className="form-textarea flex w-full"
                                    maxLength={2000}
                                    rows={2}
                                    value={approvalComment}
                                    onChange={(e) => {
                                        setApprovalComment(e.target.value)
                                    }}
                                    disabled={approvalAddGuid ? true : false}
                                />
                            </div>
                        </div>
                        <div className="flex flex-col w-full items-center justify-center">
                            <div className="flex flex-row w-full gap-1 py-2 px-2 items-center justify-start rounded-t-md text-[#8A8A8A] text-[12.8px] bg-[#333033]">
                                <button
                                    onClick={handleAddApproversPopup}
                                    className="flex flex-row w-fit gap-1 px-2 p-1 rounded-[10px] items-center justify-center bg-[#494849] hover:bg-[#656365] text-white"
                                    title="Добавить согласующих"
                                >
                                    <svg
                                        className="plus-icon"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        id="plus"
                                    >
                                        <path
                                            fill="#FFFFFF"
                                            d="M19,11H13V5a1,1,0,0,0-2,0v6H5a1,1,0,0,0,0,2h6v6a1,1,0,0,0,2,0V13h6a1,1,0,0,0,0-2Z"
                                        ></path>
                                    </svg>
                                    Добавить согласующего
                                </button>
                            </div>
                            <div className="flex flex-row w-full gap-1 p-1 py-3 items-center justify-center text-[#8A8A8A] text-[12.8px] bg-[#2A282B]">
                                <div className="flex w-10 items-center justify-center ">
                                    №
                                </div>
                                <div className="flex flex-1 items-center justify-center ">
                                    Согласующий
                                </div>
                                <div className="flex w-32 items-center justify-center ">
                                    Дата
                                </div>
                                <div className="flex w-40 items-center justify-center ">
                                    Виза
                                </div>
                                <div className="flex w-10 items-center justify-center "></div>
                            </div>
                            <div className="flex flex-col w-full h-[350px] gap-1 p-1 items-start justify-start overflow-y-auto rounded-b-md bg-[#242225]">
                                {approversAdd?.length > 0 ? (
                                    approversAdd.map((approver, index3) => (
                                        <div
                                            key={index3}
                                            className="flex flex-col w-full h-fit gap-1 p-1 items-start justify-start rounded-md bg-[#2A282B]"
                                        >
                                            <div className="flex flex-row w-full h-fit gap-1 items-center justify-center text-[14px] ">
                                                <div className="flex w-10 items-center justify-center text-[#8A8A8A]">
                                                    {approver.user_position}
                                                </div>
                                                <div className="flex flex-1 flex-row gap-2 items-center justify-start text-white ">
                                                    {approver.is_saw ? (
                                                        <div
                                                            className="flex w-5 h-5 rounded-full items-center justify-center"
                                                            title="Просмотрено"
                                                        >
                                                            <svg
                                                                className="w-3 h-3"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 24 24"
                                                                id="trash-alt"
                                                            >
                                                                <path
                                                                    fill="#FFFFFF"
                                                                    d="M21.92,11.6C19.9,6.91,16.1,4,12,4S4.1,6.91,2.08,11.6a1,1,0,0,0,0,.8C4.1,17.09,7.9,20,12,20s7.9-2.91,9.92-7.6A1,1,0,0,0,21.92,11.6ZM12,18c-3.17,0-6.17-2.29-7.9-6C5.83,8.29,8.83,6,12,6s6.17,2.29,7.9,6C18.17,15.71,15.17,18,12,18ZM12,8a4,4,0,1,0,4,4A4,4,0,0,0,12,8Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,12,14Z"
                                                                ></path>
                                                            </svg>
                                                        </div>
                                                    ) : (
                                                        <div
                                                            className="flex w-5 h-5 rounded-full items-center justify-center"
                                                            title="Не просмотрено"
                                                        >
                                                            <svg
                                                                className="w-3 h-3"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 24 24"
                                                                id="trash-alt"
                                                            >
                                                                <path
                                                                    fill="#FFFFFF"
                                                                    d="M10.94,6.08A6.93,6.93,0,0,1,12,6c3.18,0,6.17,2.29,7.91,6a15.23,15.23,0,0,1-.9,1.64,1,1,0,0,0-.16.55,1,1,0,0,0,1.86.5,15.77,15.77,0,0,0,1.21-2.3,1,1,0,0,0,0-.79C19.9,6.91,16.1,4,12,4a7.77,7.77,0,0,0-1.4.12,1,1,0,1,0,.34,2ZM3.71,2.29A1,1,0,0,0,2.29,3.71L5.39,6.8a14.62,14.62,0,0,0-3.31,4.8,1,1,0,0,0,0,.8C4.1,17.09,7.9,20,12,20a9.26,9.26,0,0,0,5.05-1.54l3.24,3.25a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Zm6.36,9.19,2.45,2.45A1.81,1.81,0,0,1,12,14a2,2,0,0,1-2-2A1.81,1.81,0,0,1,10.07,11.48ZM12,18c-3.18,0-6.17-2.29-7.9-6A12.09,12.09,0,0,1,6.8,8.21L8.57,10A4,4,0,0,0,14,15.43L15.59,17A7.24,7.24,0,0,1,12,18Z"
                                                                ></path>
                                                            </svg>
                                                        </div>
                                                    )}
                                                    {getNameUser(
                                                        approver.user_guid
                                                    )}
                                                </div>
                                                <div className="flex w-32 items-center justify-center text-white ">
                                                    {approver.approve_date
                                                        ? approver.approve_date
                                                              .split('-')
                                                              .reverse()
                                                              .join('-')
                                                              .replace(
                                                                  /[\-]/g,
                                                                  '.'
                                                              )
                                                        : '-'}
                                                </div>
                                                <div className="flex w-40 items-center justify-center text-white ">
                                                    <input
                                                        className={`flex px-2 py-1 rounded-md border-0 text-sm w-full items-center justify-center text-center focus:outline-none focus:ring-1 focus:ring-[#FFFFFF] 
                                                                            ${
                                                                                approver.status ==
                                                                                0
                                                                                    ? 'bg-[#FAD12E] text-[#3A2506]'
                                                                                    : approver.status ==
                                                                                        1
                                                                                      ? 'bg-[#049664] text-[#103917]'
                                                                                      : approver.status ==
                                                                                          2
                                                                                        ? 'bg-[#049664] text-[#103917]'
                                                                                        : approver.status ==
                                                                                            3
                                                                                          ? 'bg-[#C15C5C] text-[#611212]'
                                                                                          : ''
                                                                            }`}
                                                        value={
                                                            approver.status == 0
                                                                ? 'Согласование'
                                                                : approver.status ==
                                                                    1
                                                                  ? 'Согласовано'
                                                                  : approver.status ==
                                                                      2
                                                                    ? 'Согласовано с замечаниями'
                                                                    : approver.status ==
                                                                        3
                                                                      ? 'Не согласовано'
                                                                      : ''
                                                        }
                                                        disabled
                                                        title={
                                                            approver.status == 0
                                                                ? 'Согласование'
                                                                : approver.status ==
                                                                    1
                                                                  ? 'Согласовано'
                                                                  : approver.status ==
                                                                      2
                                                                    ? 'Согласовано с замечаниями'
                                                                    : approver.status ==
                                                                        3
                                                                      ? 'Не согласовано'
                                                                      : ''
                                                        }
                                                    ></input>
                                                </div>
                                                <div className="flex w-10 items-center justify-center ">
                                                    {approvalAddGuid ? (
                                                        approver.add_user_guid ==
                                                        user?.object.user_id ? (
                                                            approver.status ==
                                                            0 ? (
                                                                <button
                                                                    onClick={() => {
                                                                        if (
                                                                            approvalAddGuid
                                                                        ) {
                                                                            deleteApprovers(
                                                                                String(
                                                                                    approver.guid
                                                                                )
                                                                            )
                                                                            handleRemoveApprover(
                                                                                approver.user_guid
                                                                            )
                                                                        } else {
                                                                            handleRemoveApprover(
                                                                                approver.user_guid
                                                                            )
                                                                        }
                                                                    }}
                                                                    className="flex w-6 h-6 rounded-full items-center justify-center hover:bg-[#701F1F]"
                                                                    title="Удалить пльзователя"
                                                                >
                                                                    <svg
                                                                        className="w-3 h-3"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 24 24"
                                                                        id="trash-alt"
                                                                    >
                                                                        <path
                                                                            fill="#FFFFFF"
                                                                            d="M10,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,10,18ZM20,6H16V5a3,3,0,0,0-3-3H11A3,3,0,0,0,8,5V6H4A1,1,0,0,0,4,8H5V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V8h1a1,1,0,0,0,0-2ZM10,5a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1V6H10Zm7,14a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V8H17Zm-3-1a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,14,18Z"
                                                                        ></path>
                                                                    </svg>
                                                                </button>
                                                            ) : (
                                                                ''
                                                            )
                                                        ) : (
                                                            ''
                                                        )
                                                    ) : approver.status == 0 ? (
                                                        <button
                                                            onClick={() => {
                                                                if (
                                                                    approvalChangeGuid
                                                                ) {
                                                                    deleteApprovers(
                                                                        String(
                                                                            approver.guid
                                                                        )
                                                                    )
                                                                    handleRemoveApprover(
                                                                        approver.user_guid
                                                                    )
                                                                } else {
                                                                    handleRemoveApprover(
                                                                        approver.user_guid
                                                                    )
                                                                }
                                                            }}
                                                            className="flex w-6 h-6 rounded-full items-center justify-center hover:bg-[#701F1F]"
                                                            title="Удалить пльзователя"
                                                        >
                                                            <svg
                                                                className="w-3 h-3"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 24 24"
                                                                id="trash-alt"
                                                            >
                                                                <path
                                                                    fill="#FFFFFF"
                                                                    d="M10,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,10,18ZM20,6H16V5a3,3,0,0,0-3-3H11A3,3,0,0,0,8,5V6H4A1,1,0,0,0,4,8H5V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V8h1a1,1,0,0,0,0-2ZM10,5a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1V6H10Zm7,14a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V8H17Zm-3-1a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,14,18Z"
                                                                ></path>
                                                            </svg>
                                                        </button>
                                                    ) : (
                                                        ''
                                                    )}
                                                </div>
                                            </div>

                                            {approver.comment && (
                                                <div className="flex flex-col w-full gap-1 items-start justify-center">
                                                    <div className="flex w-full items-start justify-start text-[#8A8A8A] text-[12.8px]">
                                                        Комментарий
                                                    </div>
                                                    <div className="flex w-full items-start justify-start">
                                                        <textarea
                                                            className="form-textarea flex w-full"
                                                            maxLength={2000}
                                                            rows={2}
                                                            value={
                                                                approver.comment
                                                            }
                                                            disabled={true}
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    ))
                                ) : (
                                    <div className="flex w-full h-fit text-[#8A8A8A] text-[12.8px] items-center justify-center">
                                        Согласующие отсутствуют
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="flex flex-row mt-5 text-right justify-end gap-2">
                            <button
                                className="flex px-4 py-1 min-w-[120px] rounded-[10px] shadow-md text-xs items-center justify-center text-white bg-[#494849] hover:bg-[#656365]"
                                onClick={() => {
                                    setApproversAdd([])
                                    setApprovalChangeGuid('')
                                    setApprovalAddGuid('')
                                    handleApprovalPopup()
                                }}
                            >
                                Отмена
                            </button>
                            <button
                                className={`flex px-4 py-1 min-w-[120px] rounded-[10px] shadow-md text-xs  items-center justify-center  ${approversAdd.length < 1 ? 'bg-[#494849] text-[#8A8A8A]' : 'bg-[#187A33] hover:bg-[#1D9A40] text-white'}`}
                                onClick={() => {
                                    approvalChangeGuid
                                        ? handleApprovalChange()
                                        : approvalAddGuid
                                          ? handleApprovalAdd()
                                          : handleApprovalCreate()
                                }}
                                disabled={approversAdd.length < 1}
                            >
                                {isApprovalCreate ? (
                                    <svg
                                        className="animate-spin h-4 w-4 text-white"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                    >
                                        <circle
                                            className="opacity-25"
                                            cx="12"
                                            cy="12"
                                            r="10"
                                            stroke="currentColor"
                                            strokeWidth="4"
                                        ></circle>
                                        <path
                                            className="opacity-75"
                                            fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                        ></path>
                                    </svg>
                                ) : approvalChangeGuid ? (
                                    'Сохранить'
                                ) : approvalAddGuid ? (
                                    'Сохранить'
                                ) : (
                                    'Создать'
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {addApproversPopup && (
                <div
                    className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center"
                    onClick={handleAddApproversPopup}
                >
                    <div
                        className="flex flex-col p-5 rounded-lg bg-[#262427] w-[800px] h-fit gap-y-5"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <h2 className="text-white">Реестр пользователей</h2>
                        <div className="flex flex-row w-full min-h-10 p-1 gap-1 rounded-md items-center justify-start bg-[#181818] flex-wrap">
                            {selectedItems.map((item, index) => (
                                <div
                                    key={index}
                                    className="flex flex-row w-fit h-8 p-1 pl-2 gap-2 rounded-md text-white text-[12.8px] items-center justify-start bg-[#282629]"
                                >
                                    {getNameUser(item)}
                                    <button
                                        onClick={() =>
                                            handleCheckboxChange(item)
                                        }
                                        className="flex w-6 h-6 rounded-full items-center justify-center hover:bg-[#701F1F]"
                                        title="Удалить пльзователя"
                                    >
                                        <svg
                                            className="w-3 h-3"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                            id="trash-alt"
                                        >
                                            <path
                                                fill="#FFFFFF"
                                                d="M10,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,10,18ZM20,6H16V5a3,3,0,0,0-3-3H11A3,3,0,0,0,8,5V6H4A1,1,0,0,0,4,8H5V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V8h1a1,1,0,0,0,0-2ZM10,5a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1V6H10Zm7,14a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V8H17Zm-3-1a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,14,18Z"
                                            ></path>
                                        </svg>
                                    </button>
                                </div>
                            ))}
                            <input
                                className="flex p-1 min-w-40 h-8 items-center justify-start text-white text-[12.8px] bg-[#181818] focus:outline-none"
                                value={userFilter}
                                onChange={(e) =>
                                    handleInputChange(e.target.value)
                                }
                            ></input>
                        </div>
                        <div className="flex flex-col w-full h-fit items-center justify-center">
                            <div className="flex flex-row w-full gap-1 p-1 py-3 items-center justify-center rounded-t-md text-[#8A8A8A] text-[12.8px] bg-[#333033]">
                                <div className="flex w-5 items-center justify-center "></div>
                                <div className="flex w-10 items-center justify-center ">
                                    №
                                </div>
                                <div className="flex flex-1 items-center justify-center ">
                                    Фамилия Имя Отчество
                                </div>
                                <div className="flex w-48 items-center justify-center ">
                                    Подразделение
                                </div>
                                <div className="flex w-48 items-center justify-center ">
                                    Должность
                                </div>
                            </div>
                            <div className="flex flex-col w-full h-[400px] overflow-y-auto gap-1 p-1 items-start justify-start rounded-b-md bg-[#1D1C1E]">
                                {userFilteredList.map((item, index) => (
                                    <div
                                        key={item.guid}
                                        className="flex flex-col w-full h-fit gap-1 p-1 items-center justify-center rounded-md cursor-pointer bg-[#2A282B] hover:bg-[#373538]"
                                        onClick={() => {
                                            setUserFilter([''])
                                            handleCheckboxChange(
                                                String(item.guid)
                                            )
                                        }}
                                    >
                                        <div className="flex flex-row w-full gap-1 items-center justify-center text-[14px] ">
                                            <div className="flex w-5 items-center justify-center ">
                                                <input
                                                    type="checkbox"
                                                    checked={selectedItems.includes(
                                                        String(item.guid)
                                                    )}
                                                    onChange={() => {
                                                        if (
                                                            item?.guid != null
                                                        ) {
                                                            handleCheckboxChange(
                                                                String(
                                                                    item.guid
                                                                )
                                                            ) // Преобразование в строку
                                                        }
                                                    }}
                                                />
                                            </div>
                                            <div className="flex w-10 items-center justify-center text-[#8A8A8A]">
                                                {index + 1}
                                            </div>
                                            <div className="flex flex-1 items-center justify-start text-white ">
                                                {`${item.last_name ? item.last_name : ''} ${item.first_name ? item.first_name : ''} ${item.patronymic ? item.patronymic : ''}`}
                                            </div>
                                            <div className="flex w-48 items-center justify-center text-white ">
                                                {getNameStruct(
                                                    item?.struct_guid || ''
                                                )}
                                            </div>
                                            <div className="flex w-48 items-center justify-center text-white ">
                                                {item.position}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="flex flex-row text-right justify-end items-center">
                            <button
                                className="flex min-w-[120px] px-4 py-1 rounded-[10px] shadow-md items-center justify-center text-xs text-white bg-[#494849] hover:bg-[#656365]"
                                onClick={handleAddApproversPopup}
                            >
                                Отмена
                            </button>
                            <button
                                className="flex min-w-[120px] px-4 py-1 rounded-[10px] shadow-md text-xs text-white ml-2 items-center justify-center bg-[#187A33] hover:bg-[#1D9A40]"
                                onClick={handlePreAddApproval}
                            >
                                {isApprovalsAdd ? (
                                    <svg
                                        className="animate-spin h-4 w-4 text-white"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                    >
                                        <circle
                                            className="opacity-25"
                                            cx="12"
                                            cy="12"
                                            r="10"
                                            stroke="currentColor"
                                            strokeWidth="4"
                                        ></circle>
                                        <path
                                            className="opacity-75"
                                            fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                        ></path>
                                    </svg>
                                ) : (
                                    'Добавить'
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            )}

            <Footer
                user_guid={user?.object.user_id}
                position={pos}
                approve={isApprove}
                isUserRoleName={isUserRoleName}
                isSendPlan={isSendPlan}
                handleSave={handleSave}
                onPositionStatusChange={(posStatus) => {
                    setPos((prev) => ({
                        ...prev,
                        position_status: posStatus,
                    }))
                }}
                onPositionStageChange={(posStage) => {
                    setPos((prev) => ({
                        ...prev,
                        position_stage: posStage,
                    }))
                }}
            />
        </div>
    )
}

export default PlanPositionEditPage
