import React, { useState } from 'react'
import { Okved2 } from '../../../../Org/Handbook/OKVED2/types'
import FormatText from '../../../../Org/Handbook/OKVED2/components/FormatText'

const PurchaseItemsPopupOkved2: React.FC<{
    item: any
    level: number
    onSelected: (value: Okved2) => void
}> = ({ item, level, onSelected }) => {
    const [isOpen, setIsOpen] = useState(false)
    const [children, setChildren] = useState<Okved2[] | null>(null)
    const [isLoading, setLoading] = useState(false)

    const fetchData = async (okved2Code: string | null): Promise<Okved2[]> => {
        setLoading(true)
        const url =
            process.env.REACT_APP_API_URL +
            `/api/backend/v1/okved2/code?okved2_code=${okved2Code}`

        return fetch(url, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((response) => {
                if (response.ok) {
                    return response.json()
                } else {
                    throw new Error('Ошибка запроса по согласующим')
                }
            })
            .catch((e) => {
                console.error(e)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const handleClick = async () => {
        if (item.child_counts === null) return
        setIsOpen(!isOpen)
        if (!children) {
            const data = await fetchData(item.code)
            setChildren(data)
        }
    }

    return (
        <div>
            <div className="flex flex-row w-full justify-start text-center">
                {/* Отступ для вложенности */}
                <div
                    className={`flex flex-row w-full gap-1 mx-1 bg-[#2A282B] ${!/^[A-Z]+$/.test(item.code) ? (item.child_counts > 0 || item.child_counts !== null ? 'bg-[#2A282B] text-white hover:bg-[#373538]' : 'bg-[#262427] text-[#8A8A8A]') : 'bg-[#262427] text-[#8A8A8A] hover:bg-[#373538]'} text-[11px] rounded cursor-pointer`}
                    onClick={handleClick}
                    onDoubleClick={() =>
                        !/^[A-Z]+$/.test(item.code) ? onSelected(item) : ''
                    }
                >
                    <div className="flex flex-col w-full items-center justify-center">
                        <div className="flex flex-row w-full items-center justify-center">
                            {item.child_counts == null ? (
                                <div className="flex w-8 items-center justify-center text-center text-[#8A8A8A] "></div>
                            ) : isOpen ? (
                                isLoading ? (
                                    <div className="flex w-8 items-center justify-center text-center text-[#8A8A8A] ">
                                        <svg
                                            className="animate-spin h-4 w-4 text-white"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                        >
                                            <circle
                                                className="opacity-25"
                                                cx="12"
                                                cy="12"
                                                r="10"
                                                stroke="currentColor"
                                                strokeWidth="4"
                                            ></circle>
                                            <path
                                                className="opacity-75"
                                                fill="currentColor"
                                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                            ></path>
                                        </svg>
                                    </div>
                                ) : (
                                    <div className="flex w-8 items-center justify-center text-center text-[#8A8A8A] ">
                                        <svg
                                            className="w-3 h-3"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                            id="angle-up"
                                        >
                                            <path
                                                fill="#FFFFFF"
                                                d="M19,11H5a1,1,0,0,0,0,2H19a1,1,0,0,0,0-2Z"
                                            ></path>
                                        </svg>
                                    </div>
                                )
                            ) : isLoading ? (
                                <div className="flex w-8 items-center justify-center text-center text-[#8A8A8A] ">
                                    <svg
                                        className="animate-spin h-4 w-4 text-white"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                    >
                                        <circle
                                            className="opacity-25"
                                            cx="12"
                                            cy="12"
                                            r="10"
                                            stroke="currentColor"
                                            strokeWidth="4"
                                        ></circle>
                                        <path
                                            className="opacity-75"
                                            fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                        ></path>
                                    </svg>
                                </div>
                            ) : (
                                <div className="flex w-8 items-center justify-center text-center text-[#8A8A8A] ">
                                    <svg
                                        className="w-3 h-3"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        id="angle-down"
                                    >
                                        <path
                                            fill="#FFFFFF"
                                            d="M19,11H13V5a1,1,0,0,0-2,0v6H5a1,1,0,0,0,0,2h6v6a1,1,0,0,0,2,0V13h6a1,1,0,0,0,0-2Z"
                                        ></path>
                                    </svg>
                                </div>
                            )}
                            <div className="flex w-16 text-left justify-left items-center pl-1 ">
                                {item.code}
                            </div>
                            <div className="flex flex-row flex-1 items-center justify-left text-left px-2 py-2 gap-2 ">
                                <div className="flex items-center justify-left text-left">
                                    {item.name}
                                </div>
                                <div className="flex items-center justify-left text-left text-[#8A8A8A]">
                                    {item.child_counts > 0
                                        ? `(${item.child_counts})`
                                        : ``}
                                </div>
                            </div>
                        </div>
                        {item.add_info ? (
                            <div className="flex flex-row w-full items-center justify-start ">
                                <div className="flex w-8 text-left justify-left items-center pl-1 "></div>
                                <div className="flex w-16 text-left justify-left items-center pl-1 "></div>
                                <div className="flex flex-1 items-center justify-left text-left text-[11px] text-[#8A8A8A] pb-2 whitespace-pre-wrap">
                                    <FormatText text={item.add_info} />
                                </div>
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                </div>
            </div>
            {isOpen && children && (
                <div className="flex flex-col pl-3 gap-1 pt-1">
                    {children.map((child) => (
                        <PurchaseItemsPopupOkved2
                            key={child.guid}
                            item={child}
                            level={level + 1}
                            onSelected={onSelected}
                        />
                    ))}
                </div>
            )}
        </div>
    )
}

export default PurchaseItemsPopupOkved2
