import React, { useState, useEffect } from 'react'
import Section from '../../../../../../components/Section'
import MoneyInput from '../../../../../../components/form/MoneyInput'
import { Items, PositionFinancialProvisionVolumes } from '../../types'

type Props = {
    isBudgetFundsIncluded: boolean
    onBudgetFundsIncludedChange: (value: boolean) => void
    positionFinancialProvisionVolumeItems: PositionFinancialProvisionVolumes | null
    onPositionFinancialProvisionChange: (items: Items[]) => void
    isDraft: boolean
}

const PurchaseBudgetFunds: React.FC<Props> = ({
    isBudgetFundsIncluded,
    onBudgetFundsIncludedChange,
    positionFinancialProvisionVolumeItems,
    onPositionFinancialProvisionChange,
    isDraft,
}) => {
    const [items, setItems] = useState<Items[]>(
        positionFinancialProvisionVolumeItems?.items || []
    )
    const [expenseTypes, setExpenseTypes] = useState<any[]>([])
    const [filteredExpenseTypes, setFilteredExpenseTypes] = useState<any[]>([])
    const [dropdownVisibleIndex, setDropdownVisibleIndex] = useState<
        number | null
    >(null)

    // Сумма всех объемов финансового обеспечения
    const totalBudgetFunds = items.reduce(
        (acc, item) => acc + (Number(item.financial_volume_position_rub) || 0),
        0
    )

    // Получение наименования для `target_code`
    const fetchTargetName = async (targetCode: string): Promise<string> => {
        try {
            const response = await fetch(
                process.env.REACT_APP_API_URL +
                    `/api/backend/v1/financial_provision_target/code_search?text_query=${targetCode.substring(
                        3,
                        5
                    )}`,
                {
                    method: 'GET',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            )

            if (!response.ok) {
                throw new Error(`Ошибка HTTP: ${response.status}`)
            }

            const data = await response.json()
            return data[0]?.name || 'Не найдено'
        } catch (error) {
            console.error('Ошибка при загрузке target_name:', error)
            return 'Ошибка загрузки'
        }
    }

    // Получение всех видов расходов
    const fetchAllExpenseTypes = async (): Promise<
        { guid: string; code: string; name: string }[]
    > => {
        try {
            const response = await fetch(
                process.env.REACT_APP_API_URL +
                    '/api/backend/v1/financial_provision_type',
                {
                    method: 'GET',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            )

            if (!response.ok) {
                throw new Error(`Ошибка HTTP: ${response.status}`)
            }

            const data = await response.json()
            setExpenseTypes(data)
            setFilteredExpenseTypes(data)
            return data
        } catch (error) {
            console.error('Ошибка при загрузке видов расходов:', error)
            return [] // Возвращаем пустой массив в случае ошибки
        }
    }

    // Поиск видов расходов
    const searchExpenseTypes = async (query: string) => {
        try {
            const response = await fetch(
                process.env.REACT_APP_API_URL +
                    `/api/backend/v1/financial_provision_type/search?text_query=${query}`,
                {
                    method: 'GET',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            )

            if (!response.ok) {
                throw new Error(`Ошибка HTTP: ${response.status}`)
            }

            const data = await response.json()
            setFilteredExpenseTypes(data)
        } catch (error) {
            console.error('Ошибка при поиске видов расходов:', error)
        }
    }

    // Обновление `target_code` и автоматическое заполнение `target_name`
    const handleTargetCodeChange = async (value: string, index: number) => {
        const updatedItems = [...items]
        updatedItems[index].target_code = value.substring(0, 10) // Максимум 10 символов

        // Если заполнены 5 и 6 символы, запускаем запрос
        if (value.length >= 10) {
            const targetName = await fetchTargetName(value)
            updatedItems[index].target_name = targetName
        } else {
            updatedItems[index].target_name = '' // Очищаем, если код не полный
        }

        setItems(updatedItems)
        onPositionFinancialProvisionChange(updatedItems)
    }

    // Обновление объема финансового обеспечения
    const handleVolumeChange = (value: number, index: number) => {
        const updatedItems = [...items]
        updatedItems[index].financial_volume_position_rub = value
        setItems(updatedItems)
        onPositionFinancialProvisionChange(updatedItems)
    }

    // Обновление кода КВР
    const handleExpenseTypeSelect = (
        code: string,
        name: string,
        index: number
    ) => {
        const updatedItems = [...items]
        updatedItems[index].type_code = code
        updatedItems[index].type_name = name
        setItems(updatedItems)
        onPositionFinancialProvisionChange(updatedItems)
        setDropdownVisibleIndex(null)
    }

    // Автозаполнение данных при загрузке
    const autoFillItems = async () => {
        try {
            // Шаг 1: Загружаем все виды расходов (КВР)
            const fetchedExpenseTypes = await fetchAllExpenseTypes()

            // Шаг 2: Обновляем каждый элемент
            const updatedItems = await Promise.all(
                items.map(async (item) => {
                    // Получаем наименование статьи, если target_code указан
                    const targetName = item.target_code
                        ? await fetchTargetName(item.target_code)
                        : ''

                    // Находим наименование КВР по его коду
                    const expenseType = fetchedExpenseTypes.find(
                        (type) => Number(type.code) === Number(item.type_code)
                    )

                    return {
                        ...item,
                        target_name: targetName, // Обновляем наименование статьи
                        type_name: expenseType ? expenseType.name : '', // Обновляем наименование КВР
                    }
                })
            )

            // Шаг 3: Обновляем состояние
            setItems(updatedItems)
            onPositionFinancialProvisionChange(updatedItems)
        } catch (error) {
            console.error('Ошибка при автозаполнении данных:', error)
        }
    }

    useEffect(() => {
        autoFillItems()
    }, [])

    // Добавление новой позиции
    const handleAddNewItem = () => {
        const newItem: Items = {
            target_code: '',
            target_name: '',
            type_code: '',
            type_name: '',
            financial_volume_position_rub: 0,
        }

        const updatedItems = [...items, newItem]
        setItems(updatedItems)
        onPositionFinancialProvisionChange(updatedItems)
    }

    //Удаление позиции
    const handleRemoveItem = (index: number) => {
        const updatedItems = items.filter((_, i) => i !== index) // Удаляем позицию по индексу
        setItems(updatedItems)
        onPositionFinancialProvisionChange(updatedItems) // Передаём обновлённые данные в родительский компонент
    }

    return (
        <Section title="Информация о финансировании за счет бюджетных средств">
            {/* Наличие бюджетных средств */}
            <div className="flex flex-row gap-x-5">
                <div className="flex flex-col">
                    <span className="form-label">
                        Наличие бюджетных средств
                    </span>
                    <select
                        className="form-select focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                        value={isBudgetFundsIncluded ? 'yes' : 'no'}
                        onChange={(e) =>
                            onBudgetFundsIncludedChange(
                                e.target.value === 'yes'
                            )
                        }
                    >
                        <option value="yes">Да</option>
                        <option value="no">Нет</option>
                    </select>
                </div>
                {isBudgetFundsIncluded && (
                    <div className="flex flex-col">
                        <span className="form-label">
                            Объем бюджетных средств
                        </span>
                        <MoneyInput
                            value={totalBudgetFunds}
                            disabled={true}
                            className="form-input bg-[#1F1E1F] border border-[#2A282B]"
                        />
                    </div>
                )}
            </div>

            {/* Таблица данных */}
            {isBudgetFundsIncluded && (
                <div className="flex flex-col w-full min-w-fit mt-5 rounded">
                    {/* Кнопки */}
                    <div className="flex flex-row gap-1 h-10 bg-[#333033] text-[#8A8A8A] text-[10px] p-1 pl-2 rounded-t items-center">
                        {isDraft && (
                            <button
                                className="w-6 h-6 grid rounded-full place-items-center bg-[#333033] hover:bg-[#656365] "
                                onClick={handleAddNewItem}
                            >
                                <svg
                                    className="plus-icon"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    id="plus"
                                >
                                    <path
                                        fill="#FFFFFF"
                                        d="M19,11H13V5a1,1,0,0,0-2,0v6H5a1,1,0,0,0,0,2h6v6a1,1,0,0,0,2,0V13h6a1,1,0,0,0,0-2Z"
                                    ></path>
                                </svg>
                            </button>
                        )}
                    </div>
                    {/* Заголовок таблицы */}
                    <div className="flex flex-row gap-1 h-10 bg-[#2A282B] text-[#8A8A8A] text-[10px] p-1 items-center">
                        <div className="flex w-10 items-center justify-center text-center">
                            №
                        </div>
                        <div
                            className="flex w-24 items-center justify-center text-center"
                            title="Код целевой статьи расходов"
                        >
                            Код ЦСР
                        </div>
                        <div className="flex flex-1 items-center justify-center text-center">
                            Наименование федерального проекта
                        </div>
                        <div className="flex flex-1 items-center justify-center text-center">
                            Код вида расходов (КВР)
                        </div>
                        <div className="flex w-40 items-center justify-center text-center">
                            Объем финансового обеспечения (руб.)
                        </div>
                        <div className="flex w-8 items-center justify-center text-center"></div>
                    </div>
                    <div className="flex flex-col w-full items-center bg-[#242225] rounded-b">
                        {/* Строки таблицы */}
                        {items.map((item, index) => (
                            <div
                                className="flex flex-row gap-1 my-1 text-[11px] h-7 w-full px-1 text-[#8A8A8A] items-center"
                                key={index}
                            >
                                <div className="flex w-10 h-full items-center justify-center text-center">
                                    {index + 1}
                                </div>
                                <input
                                    type="text"
                                    value={item.target_code}
                                    onChange={(e) =>
                                        handleTargetCodeChange(
                                            e.target.value,
                                            index
                                        )
                                    }
                                    className="flex px-2 w-24 h-full rounded-md bg-[#181818] text-white focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                                    placeholder="Введите код"
                                />
                                <input
                                    type="text"
                                    value={item.target_name || ''}
                                    className="flex px-2 flex-1 h-full rounded-md bg-[#1F1E1F] text-white"
                                    disabled
                                    placeholder="Наименование"
                                    title={item.target_name || 'Нет данных'}
                                />
                                <div className="relative w-full flex-1 h-full">
                                    <input
                                        type="text"
                                        className="flex px-2 w-full h-full rounded-md bg-[#181818] text-white focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                                        placeholder="Введите или выберите код вида расходов (КВР)"
                                        onFocus={() =>
                                            setDropdownVisibleIndex(index)
                                        }
                                        onChange={(e) => {
                                            const inputValue = e.target.value

                                            const updatedItems = [...items]
                                            const [code] =
                                                inputValue.split(' - ') // Извлекаем только код, если пользователь вводит полный формат
                                            updatedItems[index].type_code = code
                                            updatedItems[index].type_name = '' // Сбрасываем имя при ручном вводе

                                            setItems(updatedItems)
                                            onPositionFinancialProvisionChange(
                                                updatedItems
                                            )

                                            // Выполняем запрос для фильтрации
                                            if (code) {
                                                searchExpenseTypes(code)
                                            }
                                        }}
                                        value={
                                            items[index].type_code &&
                                            items[index].type_name
                                                ? `${items[index].type_code} - ${items[index].type_name}`
                                                : items[index].type_code || ''
                                        }
                                        onBlur={() =>
                                            setTimeout(
                                                () =>
                                                    setDropdownVisibleIndex(
                                                        null
                                                    ),
                                                100
                                            )
                                        } // Задержка закрытия
                                    />
                                    {dropdownVisibleIndex === index && ( // Показываем список только для текущей строки
                                        <div className="absolute z-50 w-full h-52 bg-[#181818] border border-[#75747A] rounded shadow-md overflow-y-auto">
                                            {filteredExpenseTypes.map(
                                                (type) => (
                                                    <div
                                                        key={type.guid}
                                                        className="p-2 hover:bg-[#52525E] cursor-pointer text-white"
                                                        onMouseDown={(e) => {
                                                            // onMouseDown срабатывает до onBlur, поэтому значение выбирается корректно
                                                            e.preventDefault() // Предотвращает потерю фокуса до обработки выбора
                                                            handleExpenseTypeSelect(
                                                                type.code,
                                                                type.name,
                                                                index
                                                            )
                                                            setDropdownVisibleIndex(
                                                                null
                                                            )
                                                        }}
                                                    >
                                                        {type.code} -{' '}
                                                        {type.name}
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    )}
                                </div>
                                <MoneyInput
                                    value={item.financial_volume_position_rub}
                                    onChange={(value) =>
                                        handleVolumeChange(value, index)
                                    }
                                    className="flex px-2 w-40 h-full rounded-md bg-[#181818] text-white focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                                />
                                <div className="flex flex-row h-8 w-8 items-center justify-center text-center">
                                    <button
                                        onClick={() => handleRemoveItem(index)}
                                        className=" flex w-6 h-6 rounded-full items-center justify-center hover:bg-[#701F1F]"
                                        title="Удалить позицию"
                                    >
                                        <svg
                                            className="w-3 h-3"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                            id="trash-alt"
                                        >
                                            <path
                                                fill="#FFFFFF"
                                                d="M10,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,10,18ZM20,6H16V5a3,3,0,0,0-3-3H11A3,3,0,0,0,8,5V6H4A1,1,0,0,0,4,8H5V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V8h1a1,1,0,0,0,0-2ZM10,5a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1V6H10Zm7,14a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V8H17Zm-3-1a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,14,18Z"
                                            ></path>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </Section>
    )
}

export default PurchaseBudgetFunds
