import React, { useState, useEffect } from 'react'
import './index.css'
import { PlanPosition, ApprovalsCategory } from '../../types'

type Props = {
    keyindex: number
    planPosition: PlanPosition
    onDoubleClick: (pos_guid: string) => void
}

const STAGE_COLOR = new Map([
    ['Редактирование', '#3C69FE'],
    ['Подготовка к размещению', '#563AF5'],
    ['Отправка в ЕИС', '#3C18BC'],
    ['План закупки в ЕИС', '#272593'],
])

const STATUS_COLOR = new Map([
    ['Черновик', '#4E9AFF'],
    ['Готово', '#049664'],
    ['Подготовка', '#3C69FE'],
    ['Готово к отправке', '#563AF5'],
    ['Отправлено в ЕИС', '#049664'],
    ['Размещена', '#3C18BC'],
    ['Аннулирована', '#272593'],
])

const POSITION_STATUS = new Map([
    ['P', 'Размещена'],
    ['A', 'Аннулирована'],
    ['N', 'Новая'],
    ['C', 'Изменение'],
])

const POSITION_STATUS_COLOR = new Map([
    ['N', '#3C69FE'],
    ['C', '#563AF5'],
    ['A', 'transparent'],
    ['P', '#3C18BC'],
])

const POSITION_DRAFT_STATUS = new Map([
    ['P', 'Размещение'],
    ['A', 'Аннулирование позиции'],
    ['N', 'Новая позиция'],
    ['C', 'Изменение позиции'],
])

const POSITION_DRAFT_STATUS_COLOR = new Map([
    ['N', '#8F8E94'],
    ['C', '#737279'],
    ['A', '#515055'],
    ['P', '#2A282B'],
])

const MONTHS = [
    'январь',
    'февраль',
    'март',
    'апрель',
    'май',
    'июнь',
    'июль',
    'август',
    'сентябрь',
    'октябрь',
    'ноябрь',
    'декабрь',
]

const CURRENCY = new Map([
    ['643', '₽'],
    ['156', '¥'],
    ['978', '€'],
    ['840', '$'],
])

const GWS = new Map([
    ['G', 'Товар'],
    ['W', 'Работа'],
    ['S', 'Услуга'],
])

const ApprovalsTableRow: React.FC<Props> = ({
    keyindex,
    planPosition,
    onDoubleClick,
}) => {
    return (
        <>
            <div
                className={`${planPosition.is_saw == true ? 'plan-position-table-row' : 'flex flex-row gap-1 mx-1 cursor-pointer bg-[#373538] hover:bg-[#464447] text-white text-[11px] rounded'}`}
                onDoubleClick={() => onDoubleClick(planPosition.guid)}
            >
                <div className="flex h-[70px] w-12 items-center justify-center text-center text-[#8A8A8A]">
                    {keyindex}
                </div>
                <div className="flex h-[70px] w-10 items-center justify-center">
                    <div
                        className={`flex flex-row w-4 h-4  rounded-full 
                                ${
                                    planPosition.approval_status == 0
                                        ? 'bg-[#FAB502]'
                                        : planPosition.approval_status == 1 ||
                                            planPosition.approval_status == 2
                                          ? 'bg-[#049664]'
                                          : planPosition.approval_status == 3
                                            ? 'bg-[#C15C5C]'
                                            : planPosition.approval_status == 4
                                              ? 'bg-[#464447]'
                                              : planPosition.approval_status ==
                                                  null
                                                ? 'bg-[#464447]'
                                                : ''
                                }
                                `}
                        title={
                            planPosition.approval_status == 0
                                ? 'Идет согласование'
                                : planPosition.approval_status == 1 ||
                                    planPosition.approval_status == 2
                                  ? 'Согласовано'
                                  : planPosition.approval_status == 3
                                    ? 'Не согласовано'
                                    : planPosition.approval_status == 4
                                      ? 'Отменено'
                                      : planPosition.approval_status == null
                                        ? 'Отсутствует'
                                        : ''
                        }
                    ></div>
                </div>
                <div className="flex h-[70px] w-12 items-center justify-center text-center text-[#8A8A8A]">
                    {planPosition.ordinal_number}
                </div>
                <div
                    className={`flex h-[70px] w-24 items-center justify-center text-center rounded`}
                    style={{
                        backgroundColor: planPosition.position_stage
                            ? STAGE_COLOR.get(planPosition.position_stage)
                            : 'transparent',
                    }}
                >
                    {planPosition.position_stage}
                </div>
                <div
                    className="flex h-[70px] w-24 items-center justify-center text-center rounded"
                    style={{
                        backgroundColor: planPosition.position_status
                            ? STATUS_COLOR.get(planPosition.position_status)
                            : 'transparent',
                    }}
                >
                    {planPosition.position_status}
                </div>
                <div
                    className="flex h-[70px] flex-1 items-center justify-start text-left min-w-44 px-2 "
                    title={planPosition.contract_subject}
                >
                    <p className="fourrow">
                        {planPosition.contract_subject
                            ? planPosition.contract_subject
                            : '-'}
                    </p>
                </div>
                <div className="flex h-[70px] w-28 items-center justify-end text-right px-2 ">
                    {Number(planPosition.maximum_contract_price).toLocaleString(
                        'ru-RU',
                        {
                            minimumFractionDigits: 2,
                        }
                    )}{' '}
                    {planPosition.currency_digital_code
                        ? CURRENCY.get(
                              String(planPosition.currency_digital_code)
                          )
                        : ''}
                </div>
                <div className="flex h-[70px] w-24 items-center justify-end text-right px-2 ">
                    {planPosition.purchase_planned_date === null ? (
                        planPosition.purchase_period_month ? (
                            <>
                                {MONTHS[
                                    Number(planPosition.purchase_period_month) -
                                        1
                                ] + ' '}
                                {planPosition.purchase_period_year}
                            </>
                        ) : (
                            '-'
                        )
                    ) : (
                        planPosition.purchase_planned_date
                            ?.split('-')
                            .reverse()
                            .join('-')
                            .replace(/[\-]/g, '.')
                    )}
                </div>
                <div className="flex h-[70px] w-24 items-center justify-end text-right px-2 ">
                    {planPosition.contract_end_date ? (
                        <>
                            {MONTHS[
                                Number(
                                    planPosition.contract_end_date?.slice(5, 7)
                                ) - 1
                            ] + ' '}
                            {planPosition.contract_end_date?.slice(0, 4)}
                        </>
                    ) : (
                        '-'
                    )}
                </div>
                <div className="flex h-[70px] w-44 items-center justify-center text-center">
                    <p className="fourrow">
                        {planPosition.purchase_method_name
                            ? planPosition.purchase_method_name
                            : '-'}
                    </p>
                </div>
                <div
                    className={
                        'flex h-[70px] w-24 items-center justify-center text-center rounded'
                    }
                    style={{
                        backgroundColor: planPosition.status
                            ? POSITION_DRAFT_STATUS_COLOR.get(
                                  planPosition.status
                              )
                            : 'transparent',
                    }}
                >
                    {POSITION_DRAFT_STATUS.get(String(planPosition.status))}
                </div>
                <div className="flex h-[70px] w-20 items-center justify-center text-center">
                    {planPosition.type_object_purchase_position
                        ? GWS.get(
                              String(planPosition.type_object_purchase_position)
                          )
                        : '-'}
                </div>
                <div className="flex h-[70px] w-20 items-center justify-center text-center">
                    {planPosition.struct_name !== undefined &&
                    planPosition.struct_name !== null &&
                    planPosition.struct_name !== ''
                        ? `${planPosition.struct_name}`
                        : '-'}
                </div>
                <div className="flex h-[70px] w-20 items-center justify-center text-center">
                    {planPosition.expense_items_name !== undefined &&
                    planPosition.expense_items_name !== null &&
                    planPosition.expense_items_name !== ''
                        ? `${planPosition.expense_items_name}`
                        : '-'}
                </div>
                <div className="flex h-[70px] w-20 items-center justify-center text-center text-[#8A8A8A]">
                    {planPosition.position_version}
                </div>
                <div className="flex h-[70px] w-20 items-center justify-center text-center text-[#8A8A8A]">
                    {planPosition.sys_updated_at
                        ?.slice(0, 10)
                        .split('-')
                        .reverse()
                        .join('-')
                        .replace(/[\-]/g, '.')}
                    <br />
                    {planPosition.sys_updated_at?.slice(11, 16)}
                </div>
                <div className="flex h-[70px] w-14 items-center justify-center text-center text-[#8A8A8A]">
                    <div
                        className="flex h-8 w-8 rounded-full items-center justify-center text-center text-[#8A8A8A] bg-[#333033]"
                        title={
                            planPosition.first_name !== null
                                ? planPosition.last_name +
                                  ' ' +
                                  planPosition.first_name
                                : 'Отсутствует'
                        }
                    >
                        {planPosition.first_name !== null
                            ? planPosition.last_name?.charAt(0) +
                              planPosition.first_name?.charAt(0)
                            : 'О'}
                    </div>
                </div>
            </div>
        </>
    )
}

export default ApprovalsTableRow
