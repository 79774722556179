import React, { useState } from 'react'
import { Okpd2 } from '../../../../Org/Handbook/OKPD2/types'
import FormatText from '../../../../Org/Handbook/OKPD2/components/FormatText'

const PurchaseItemsPopupOkpd2Search: React.FC<{
    item: any
    onSelected: (value: Okpd2) => void
    posMSPStatus: boolean | null
}> = ({ item, onSelected, posMSPStatus }) => {
    return (
        <div>
            <div className="flex flex-row w-full justify-start text-center">
                {/* Отступ для вложенности */}
                <div
                    className={`flex flex-row w-full gap-1 mx-1 ${item.is_leaf ? (item.is_msp == posMSPStatus ? 'bg-[#2A282B] text-white hover:bg-[#373538] cursor-pointer' : 'bg-[#262427] text-[#8A8A8A]') : 'bg-[#262427] text-[#8A8A8A]'} text-[11px] rounded`}
                    onDoubleClick={() =>
                        item.is_leaf &&
                        item.is_msp == posMSPStatus &&
                        onSelected(item)
                    }
                >
                    <div className="flex flex-col w-full items-center justify-center">
                        <div className="flex flex-row w-full items-center justify-center">
                            <div className="flex w-8 items-center justify-center text-center text-[#8A8A8A]"></div>
                            <div className="flex w-20 text-left justify-left items-center pl-1">
                                {item.code}
                            </div>
                            <div className="flex flex-row flex-1 items-center justify-left text-left px-2 py-2 gap-2">
                                <div className="flex items-center justify-left text-left">
                                    {item.name}
                                </div>
                            </div>
                            <div className="flex flex-row w-fit items-center justify-end text-left px-2 py-2 gap-1 ">
                                <div className="flex w-24 text-left justify-center items-center pl-1 ">
                                    {item.is_msp ? (
                                        <svg
                                            className="w-5 h-5"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                            id="check"
                                        >
                                            <path
                                                fill="#187A33"
                                                d="M18.71,7.21a1,1,0,0,0-1.42,0L9.84,14.67,6.71,11.53A1,1,0,1,0,5.29,13l3.84,3.84a1,1,0,0,0,1.42,0l8.16-8.16A1,1,0,0,0,18.71,7.21Z"
                                            ></path>
                                        </svg>
                                    ) : (
                                        ''
                                    )}
                                </div>
                                <div className="flex w-24 text-left justify-center items-center pl-1 ">
                                    {item.is_rf_ban ? (
                                        <svg
                                            className="w-5 h-5"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                            id="check"
                                        >
                                            <path
                                                fill="#EF4444"
                                                d="M18.71,7.21a1,1,0,0,0-1.42,0L9.84,14.67,6.71,11.53A1,1,0,1,0,5.29,13l3.84,3.84a1,1,0,0,0,1.42,0l8.16-8.16A1,1,0,0,0,18.71,7.21Z"
                                            ></path>
                                        </svg>
                                    ) : (
                                        ''
                                    )}
                                </div>
                                <div className="flex w-24 text-left justify-center items-center pl-1 ">
                                    {item.is_rf_limit ? (
                                        <svg
                                            className="w-5 h-5"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                            id="check"
                                        >
                                            <path
                                                fill="#FAB502"
                                                d="M18.71,7.21a1,1,0,0,0-1.42,0L9.84,14.67,6.71,11.53A1,1,0,1,0,5.29,13l3.84,3.84a1,1,0,0,0,1.42,0l8.16-8.16A1,1,0,0,0,18.71,7.21Z"
                                            ></path>
                                        </svg>
                                    ) : (
                                        ''
                                    )}
                                </div>
                                <div className="flex w-24 text-left justify-center items-center pl-1 ">
                                    {item.is_rf_quota ? (
                                        <div className="flex flex-row justify-center items-center gap-1">
                                            <svg
                                                className="w-5 h-5"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                                id="check"
                                            >
                                                <path
                                                    fill="#187A33"
                                                    d="M18.71,7.21a1,1,0,0,0-1.42,0L9.84,14.67,6.71,11.53A1,1,0,1,0,5.29,13l3.84,3.84a1,1,0,0,0,1.42,0l8.16-8.16A1,1,0,0,0,18.71,7.21Z"
                                                ></path>
                                            </svg>
                                            <p>({item.quota_percent}%)</p>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </div>
                                <div className="flex w-24 text-left justify-center items-center pl-1 ">
                                    {item.is_electronic ? (
                                        <svg
                                            className="w-5 h-5"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                            id="check"
                                        >
                                            <path
                                                fill="#187A33"
                                                d="M18.71,7.21a1,1,0,0,0-1.42,0L9.84,14.67,6.71,11.53A1,1,0,1,0,5.29,13l3.84,3.84a1,1,0,0,0,1.42,0l8.16-8.16A1,1,0,0,0,18.71,7.21Z"
                                            ></path>
                                        </svg>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            </div>
                        </div>
                        {item.add_info ? (
                            <div className="flex flex-row w-full items-center justify-start ">
                                <div className="flex w-14 text-left justify-left items-center pl-1 "></div>
                                <div className="flex w-16 text-left justify-left items-center pl-1 "></div>
                                <div className="flex flex-1 items-center justify-left text-left text-[11px] text-[#8A8A8A] pb-2 whitespace-pre-wrap">
                                    <FormatText text={item.add_info} />
                                </div>
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PurchaseItemsPopupOkpd2Search
