import React from 'react'

interface FormatTextProps {
    text: string
}

const FormatText: React.FC<FormatTextProps> = ({ text }) => {
    let formatted = text

    // 1. Форматируем текст: добавляем переводы строки после заголовков и перед элементами списка
    formatted = formatted.replace(
        /(Эта группировка (?:включает|также включает|не включает):)\s*/g,
        '$1\n'
    )
    formatted = formatted.replace(/;\s*-\s*/g, ';\n- ')
    formatted = formatted.replace(
        /([^\n])\s*(Эта группировка (?:также включает|не включает):)/g,
        '$1\n\n$2'
    )
    formatted = formatted.trim()

    // 2. Разбиваем текст на строки по символу новой строки
    const lines = formatted.split('\n')

    // 3. Регулярное выражение для поиска нужных фраз.
    // Используем негативные lookbehind и lookahead, чтобы совпадение не было частью другого слова.
    // Здесь [A-Za-zА-Яа-яЁё] охватывает латинские и кириллические буквы.
    const tokenRegex =
        /(?<![A-Za-zА-Яа-яЁё])(включает также|также включает|не включает|включает)(?![A-Za-zА-Яа-яЁё])/g

    return (
        <div className="text-[11px] text-[#8A8A8A] pb-2">
            {lines.map((line, lineIndex) => {
                // Если строка начинается с тире (пункт списка), добавляем отступ
                const indentClass = line.trim().startsWith('-') ? 'ml-4' : ''

                // Разбиваем строку на части по найденным токенам
                const parts = line.split(tokenRegex)
                return (
                    <div
                        key={lineIndex}
                        className={`whitespace-pre-wrap ${indentClass}`}
                    >
                        {parts.map((part, partIndex) => {
                            if (part === 'не включает') {
                                return (
                                    <span
                                        key={partIndex}
                                        className="text-red-500"
                                    >
                                        {part}
                                    </span>
                                )
                            } else if (
                                part === 'включает' ||
                                part === 'также включает' ||
                                part === 'включает также'
                            ) {
                                return (
                                    <span
                                        key={partIndex}
                                        className="text-green-500"
                                    >
                                        {part}
                                    </span>
                                )
                            }
                            return (
                                <React.Fragment key={partIndex}>
                                    {part}
                                </React.Fragment>
                            )
                        })}
                    </div>
                )
            })}
        </div>
    )
}

export default FormatText
