import React, { useState, useMemo } from 'react'
import { Okpd2 } from './types'
import Okpd2Item from './components/Okpd2Item'
import Okpd2ItemSearch from './components/Okpd2ItemSearch'
import LoadingIcon from '../../../../../components/LoadingIcon'
import useDebounce from '../../../../../hooks/useDebounce'
import useFetch from '../../../../../hooks/useFetch'

const OrgHandbookOkpd2Page: React.FC = () => {
    const [isFiltering, setIsFiltering] = useState(true)

    const [query, setQuery] = useState('')
    const debouncedQuery = useDebounce(query, 500)

    const apiUrl = debouncedQuery
        ? process.env.REACT_APP_API_URL +
          (/^[0-9]/.test(debouncedQuery[0])
              ? `/api/backend/v1/okpd2/search_code?text_query=${encodeURIComponent(debouncedQuery)}`
              : `/api/backend/v1/okpd2/search_name?text_query=${encodeURIComponent(debouncedQuery)}`)
        : process.env.REACT_APP_API_URL + `/api/backend/v1/okpd2/code`

    // Опции для fetch, мемоизированные с помощью useMemo
    const fetchOptions: RequestInit = useMemo(
        () => ({
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        }),
        []
    ) // зависимости пустые, объект будет создан один раз

    const { data, loading, error } = useFetch<Okpd2[]>(apiUrl, fetchOptions)

    const handlerFilter = () => {
        setIsFiltering(!isFiltering)
        setQuery('')
    }

    const handlerSearchClear = () => {
        setQuery('')
    }

    return (
        <div className="flex flex-col text-white h-full">
            <div className="bg-[#111111]">
                <div className="w-auto h-20 mx-6 flex flex-row gap-x-5 ">
                    <div className="h-20 my-auto content-center ">
                        <p className="text-sm text-white">
                            Общероссийский классификатор продукции по видам
                            экономической деятельности (ОКПД 2)
                        </p>
                    </div>
                </div>
            </div>

            <div className="flex flex-1 justify-center max-h-[calc(100vh_-_80px)] p-5">
                <div className="flex flex-col h-full w-[1260px] space-y-10 bg-slate-600">
                    {/* // body */}
                    <div className="flex bg-[#181818] h-[calc(100vh_-_120px)]">
                        {/* table */}
                        <div className="flex flex-col h-full w-full">
                            {/* toolbar */}
                            <div className="flex flex-row gap-1 text-white p-2 bg-[#333033] rounded-t-lg items-center">
                                {/* Filter */}
                                <button
                                    className="w-6 h-6 grid rounded-full place-items-center hover:bg-[#656365]"
                                    onClick={handlerFilter}
                                    title={
                                        isFiltering
                                            ? 'Убрать фильтр'
                                            : 'Показать фильтр'
                                    }
                                >
                                    {isFiltering ? (
                                        <svg
                                            className="w-3 h-3"
                                            xmlns="http://www.w3.org/2000/svg"
                                            data-name="Layer 1"
                                            viewBox="0 0 24 24"
                                            id="filter-slash"
                                        >
                                            <path
                                                id="svg_1"
                                                d="m19,2l-14,0a3,3 0 0 0 -3,3l0,1.17a3,3 0 0 0 0.25,1.2l0,0.06a2.81,2.81 0 0 0 0.59,0.86l6.16,6.12l0,6.59a1,1 0 0 0 0.47,0.85a1,1 0 0 0 0.53,0.15a1,1 0 0 0 0.45,-0.11l4,-2a1,1 0 0 0 0.55,-0.89l0,-4.59l6.12,-6.12a2.81,2.81 0 0 0 0.59,-0.86l0,-0.06a3,3 0 0 0 0.29,-1.2l0,-1.17a3,3 0 0 0 -3,-3zm-5.71,11.29a1,1 0 0 0 -0.29,0.71l0,4.38l-2,1l0,-5.38a1,1 0 0 0 -0.29,-0.71l-5.3,-5.29l13.18,0l-5.3,5.29zm6.71,-7.29l-16,0l0,-1a1,1 0 0 1 1,-1l14,0a1,1 0 0 1 1,1l0,1z"
                                                fill="#FFFFFF"
                                            />
                                            <path
                                                id="svg_2"
                                                d="m10.35653,-2.20952c0,-0.54338 0.45662,-1 1,-1l0.14286,0c0.54338,0 1,0.45662 1,1l0,25.07412c0,0.54338 -0.45662,1 -1,1l-0.14286,0c-0.54338,0 -1,-0.45662 -1,-1l0,-25.07412z"
                                                transform="rotate(-43.639, 11.428, 10.3275)"
                                                opacity="undefined"
                                                fill="#ff0000"
                                            />
                                        </svg>
                                    ) : (
                                        <svg
                                            className="w-3 h-3"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                            id="filter"
                                        >
                                            <path
                                                fill="#FFFFFF"
                                                d="M19,2H5A3,3,0,0,0,2,5V6.17a3,3,0,0,0,.25,1.2l0,.06a2.81,2.81,0,0,0,.59.86L9,14.41V21a1,1,0,0,0,.47.85A1,1,0,0,0,10,22a1,1,0,0,0,.45-.11l4-2A1,1,0,0,0,15,19V14.41l6.12-6.12a2.81,2.81,0,0,0,.59-.86l0-.06A3,3,0,0,0,22,6.17V5A3,3,0,0,0,19,2ZM13.29,13.29A1,1,0,0,0,13,14v4.38l-2,1V14a1,1,0,0,0-.29-.71L5.41,8H18.59ZM20,6H4V5A1,1,0,0,1,5,4H19a1,1,0,0,1,1,1Z"
                                            ></path>
                                        </svg>
                                    )}
                                </button>
                            </div>

                            {/* name */}
                            <div className="flex flex-row gap-1 h-12 bg-[#2A282B] text-[#8A8A8A] text-[10px] p-1">
                                <div className="flex flex-col w-8 justify-center items-center text-center"></div>
                                <div className="flex flex-col w-16 justify-center items-center text-center">
                                    Код
                                </div>
                                <div className="flex flex-1 items-center justify-center text-center">
                                    Наименование и описание
                                </div>
                                <div className="flex flex-row w-fit items-center justify-end text-left px-2 py-2 gap-1 ">
                                    <div className="flex w-24 text-center justify-center items-center pl-1 ">
                                        Закупка <br /> МСП
                                    </div>
                                    <div className="flex w-24 text-center justify-center items-center pl-1 ">
                                        Нацрежим <br /> (запрет)
                                    </div>
                                    <div className="flex w-24 text-center justify-center items-center pl-1 ">
                                        Нацрежим <br /> (ограничение)
                                    </div>
                                    <div className="flex w-24 text-center justify-center items-center pl-1 ">
                                        Минимальная доля товаров из РФ
                                    </div>
                                    <div className="flex w-24 text-center justify-center items-center pl-1 ">
                                        В электронной форме
                                    </div>
                                </div>
                                <div className="flex flex-col w-2 justify-center items-center text-center"></div>
                            </div>

                            {/* filter */}
                            {isFiltering ? (
                                <div className="flex flex-row gap-1 h-10 bg-[#2A282B] text-[#8A8A8A] text-[10px] p-1">
                                    <div className="flex w-6 items-center justify-center text-center">
                                        {loading && (
                                            <div className="flex w-6 items-center justify-center text-center text-[#8A8A8A]">
                                                <svg
                                                    className="animate-spin h-4 w-4 text-white"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                >
                                                    <circle
                                                        className="opacity-25"
                                                        cx="12"
                                                        cy="12"
                                                        r="10"
                                                        stroke="currentColor"
                                                        strokeWidth="4"
                                                    ></circle>
                                                    <path
                                                        className="opacity-75"
                                                        fill="currentColor"
                                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                                    ></path>
                                                </svg>
                                            </div>
                                        )}
                                    </div>
                                    <div className="flex flex-col flex-1 justify-center items-center text-center">
                                        <input
                                            className="w-full px-2 py-1 rounded-md border-0 text-[11px] bg-[#181818] text-white focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                                            placeholder="Поиск по коду или наименованию"
                                            value={query}
                                            onChange={(e) =>
                                                setQuery(e.target.value)
                                            }
                                        />
                                    </div>
                                    <div className="flex w-6 items-center justify-center text-center">
                                        <button
                                            className="w-6 h-6 grid rounded-full place-items-center hover:bg-[#656365]"
                                            onClick={handlerSearchClear}
                                            title="Очистить фильтр"
                                        >
                                            <svg
                                                className="w-3 h-3"
                                                xmlns="http://www.w3.org/2000/svg"
                                                data-name="Layer 1"
                                                viewBox="0 0 24 24"
                                                id="x"
                                            >
                                                <path
                                                    fill="#FFFFFF"
                                                    d="M8 19a3 3 0 0 1-3-3V8a3 3 0 0 1 3-3 1 1 0 0 0 0-2 5 5 0 0 0-5 5v8a5 5 0 0 0 5 5 1 1 0 0 0 0-2Zm7.71-3.29a1 1 0 0 0 0-1.42L13.41 12l2.3-2.29a1 1 0 0 0-1.42-1.42L12 10.59l-2.29-2.3a1 1 0 0 0-1.42 1.42l2.3 2.29-2.3 2.29a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0l2.29-2.3 2.29 2.3a1 1 0 0 0 1.42 0ZM16 3a1 1 0 0 0 0 2 3 3 0 0 1 3 3v8a3 3 0 0 1-3 3 1 1 0 0 0 0 2 5 5 0 0 0 5-5V8a5 5 0 0 0-5-5Z"
                                                ></path>
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            ) : (
                                ''
                            )}

                            {/* data */}
                            <div className="flex flex-col overflow-y-auto gap-1 py-1 bg-[#1D1C1E] h-dvh">
                                {/* Вывод списка ОКВЭД2 */}
                                {error && <p>Ошибка: {error.message}</p>}
                                {isFiltering ? (
                                    ''
                                ) : loading ? (
                                    <div className="flex w-full h-fit py-5 items-center justify-center">
                                        <LoadingIcon />
                                    </div>
                                ) : (
                                    ''
                                )}
                                {data &&
                                    (data.length > 0 ? (
                                        data.map((item) =>
                                            query == '' ? (
                                                <Okpd2Item
                                                    key={item.guid}
                                                    item={item}
                                                    level={0}
                                                />
                                            ) : (
                                                <Okpd2ItemSearch
                                                    key={item.guid}
                                                    item={item}
                                                />
                                            )
                                        )
                                    ) : (
                                        <div className="flex w-full h-full items-center justify-center">
                                            Ничего не найдено
                                        </div>
                                    ))}
                            </div>

                            {/* buttons */}
                            <div className="flex flex-row gap-1 text-white p-2 bg-[#333033] rounded-b-lg h-10 space-x-2"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OrgHandbookOkpd2Page
