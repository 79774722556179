import { useState, useEffect } from 'react'

interface FetchResult<T> {
    data: T | null
    loading: boolean
    error: Error | null
}

/**
 * Хук useFetch для выполнения HTTP-запросов.
 * @param url — URL для запроса. Если значение null, запрос не выполняется.
 * @param options — опции для fetch (метод, заголовки и т.д.)
 * @returns Объект с данными, состоянием загрузки и ошибкой.
 */
function useFetch<T = unknown>(
    url: string | null,
    options?: RequestInit
): FetchResult<T> {
    const [data, setData] = useState<T | null>(null)
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<Error | null>(null)

    useEffect(() => {
        // Если URL не задан, запрос не выполняется
        if (!url) {
            return
        }

        setLoading(true)

        fetch(url, options)
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`Ошибка сети: ${response.statusText}`)
                }
                return response.json()
            })
            .then((json: T) => {
                setData(json)
                setError(null)
            })
            .catch((err: Error) => {
                setError(err)
                setData(null)
            })
            .finally(() => {
                setLoading(false)
            })
        // Используем JSON.stringify для options, чтобы следить за изменениями объекта.
    }, [url, JSON.stringify(options)])

    return { data, loading, error }
}

export default useFetch
