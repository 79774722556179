import { useState, useEffect } from 'react'

/**
 * Хук useDebounce
 * @param {any} value - значение, которое нужно задебаунсить
 * @param {number} delay - задержка в миллисекундах
 * @returns {any} debouncedValue - задебаунсенное значение
 */
function useDebounce<T>(value: T, delay: number): T {
    const [debouncedValue, setDebouncedValue] = useState<T>(value)

    useEffect(() => {
        // Устанавливаем таймер для обновления значения через delay мс
        const handler = setTimeout(() => {
            setDebouncedValue(value)
        }, delay)

        // Если значение изменилось до истечения delay, сбрасываем таймер
        return () => {
            clearTimeout(handler)
        }
    }, [value, delay])

    return debouncedValue
}

export default useDebounce
